import axios from 'axios';
import HttpStatus from 'http-status';

import config from '../config';

import * as authService from '../service/auth';

import routes from '../constants/routes';
import * as endpoints from '../constants/endpoints';

const instance = axios.create({
  baseURL: config.baseURI,
  responseType: 'json',
});

/**
 * Interceptor to catch the unauthorized responses and refresh the access token.
 *
 * @param {any} error
 * @returns {Promise}
 */
const unauthorizedResponseHandlerInterceptor = async (error) => {
  const originalRequest = error.config;
  const errorResponse = error?.response ?? error;
  const statusCode = errorResponse?.status ?? error?.response?.data?.error ?? '';

  const errorInfo = {
    statusCode,
    message: errorResponse?.data?.errorResponse ?? {},
    errorDetail: error,
  };

  // If server sent an error, override it here
  if (errorResponse?.data?.message) {
    errorInfo.message = errorResponse.data.message;
  } else if (errorResponse?.data?.error?.message) {
    errorInfo.message = errorResponse.data.error.message;
  } else if (errorResponse?.message) {
    errorInfo.message = errorResponse.message;
  } else if (errorResponse?.statusText) {
    errorInfo.message = errorResponse.statusText;
  }

  // If the request is a retry request, simply clear the tokens.
  if (originalRequest.__isRetryRequest) {
    clearLocalAuth();

    return Promise.reject(errorInfo);
  }

  if (
    statusCode === HttpStatus.UNAUTHORIZED &&
    ![endpoints.REFRESH_ACCESS_TOKEN, endpoints.LOGIN_ADMIN].includes(originalRequest.url)
  ) {
    originalRequest.__isRetryRequest = true;

    try {
      const refreshToken = authService.getRefreshToken();

      if (!refreshToken) {
        return clearLocalAuth();
      }

      const accessToken = await authService.refreshAccessToken(refreshToken);

      const newRequest = {
        ...originalRequest,
        headers: {
          ...originalRequest.headers,
          Authorization: `Bearer ${accessToken}`,
        },
      };

      return instance.request(newRequest);
    } catch (error) {
      clearLocalAuth();
    }
  }

  return Promise.reject(errorInfo);
};

/**
 * Initialize the unauthorized response interceptors.
 */
instance.interceptors.response.use(
  /**
   * Leave response as it is for a success response.
   *
   * @param {any} response
   */
  (response) => response,
  /**
   * This interceptor checks if the response had a 401 status code, which means
   * that the access token used for the request has expired. It then refreshes
   * the access token and resends the original request.
   */
  unauthorizedResponseHandlerInterceptor
);

/**
 * Clear tokens and redirect to login page.
 */
const clearLocalAuth = () => {
  authService.clearAuthTokens();
  window.location.href = routes.AUTH.LOGIN;
};

/**
 * @param {String} url The url for the api request (without the base).
 * @param {Object} [config]
 * @param {Object} [config.params] An object of queries that will be added to
 * the url.
 * @param {Boolean} [config.accessToken] Whether or not to include the
 * access-token header.
 * @returns {Promise}
 */
const get = (url, { params = {}, accessToken = true, responseType = 'json', headers = {} } = {}) => {
  const authHeaders = {};

  if (accessToken) {
    authHeaders.Authorization = `Bearer ${authService.getAccessToken()}`;
  }

  return instance({
    url,
    params,
    responseType,
    method: 'GET',
    headers: { ...authHeaders, ...headers },
  }).then((response) => response.data);
};

/**
 * @param {String} url The url for the api request (without the base).
 * @param {Object} [config]
 * @param {Object} [config.params] An object of queries that will be added to
 * the url.
 * @param {Object} [config.body] An object that will be sent in the request
 * body.
 * @param {Boolean} [config.accessToken] Whether or not to include the
 * access-token header.
 * @returns {Promise}
 */
const post = (url, { params = {}, body = {}, accessToken = true, headers = {} } = {}) => {
  const authHeaders = {};

  if (accessToken) {
    authHeaders.Authorization = `Bearer ${authService.getAccessToken()}`;
  }

  return instance({
    url,
    params,
    data: body,
    method: 'POST',
    headers: { ...authHeaders, ...headers },
  }).then((response) => response.data);
};

/**
 * @param {String} url The url for the api request (without the base).
 * @param {Object} [config]
 * @param {Object} [config.params] An object of queries that will be added to
 * the url.
 * @param {Object} [config.body] An object that will be sent in the request
 * body.
 * @param {Boolean} [config.accessToken] Whether or not to include the
 * access-token header.
 * @returns {Promise}
 */
const put = (url, { params = {}, body = {}, accessToken = true, headers = {} } = {}) => {
  const authHeaders = {};

  if (accessToken) {
    authHeaders.Authorization = `Bearer ${authService.getAccessToken()}`;
  }

  return instance({
    url,
    params,
    data: body,
    method: 'PUT',
    headers: { ...authHeaders, ...headers },
  }).then((response) => response.data);
};

/**
 * @param {String} url The url for the api request (without the base).
 * @param {Object} [config]
 * @param {Object} [config.params] An object of queries that will be added to
 * the url.
 * @param {Object} [config.body] An object that will be sent in the request
 * body.
 * @param {Boolean} [config.accessToken] Whether or not to include the
 * access-token header.
 * @returns {Promise}
 */
const patch = (url, { params = {}, body = {}, accessToken = true, headers = {} } = {}) => {
  const authHeaders = {};

  if (accessToken) {
    authHeaders.Authorization = `Bearer ${authService.getAccessToken()}`;
  }

  return instance({
    url,
    params,
    data: body,
    method: 'PATCH',
    headers: { ...authHeaders, ...headers },
  }).then((response) => response.data);
};

/**
 * @param {String} url The url for the api request (without the base).
 * @param {Object} [config]
 * @param {Object} [config.params] An object of queries that will be added to
 * the url.
 * @param {Boolean} [config.accessToken] Whether or not to include the
 * access-token header.
 * @returns {Promise}
 */
const remove = (url, { params = {}, accessToken = true, headers = {} } = {}) => {
  const authHeaders = {};

  if (accessToken) {
    authHeaders.Authorization = `Bearer ${authService.getAccessToken()}`;
  }

  return instance({
    url,
    params,
    method: 'DELETE',
    headers: { ...authHeaders, ...headers },
  }).then((response) => response.data);
};

export default {
  get,
  put,
  post,
  patch,
  delete: remove,
};
