const routes = {
  ROOT: '/',
  HOME: '/home',
  AUTH: {
    LOGIN: '/login',
  },
  USER: {
    EDIT_PROFILE: '/edit-profile',
  },

  USERS: '/users',
  CUSTOMERS: '/customers',
  EDIT_CUSTOMER: '/customers/:id/edit',

  DEALS: '/deals',
  CREATE_DEAL: '/deals/create',
  EDIT_DEAL: '/deals/edit/:dealId',

  LOCATION: '/locations',
  CATEGORIES: '/categories',
  CREATE_CATEGORY: '/categories/create',
  EDIT_CATEGORY: '/categories/:id/edit',
  ADD_LOCATION: '/locations/create',
  EDIT_LOCATION: '/locations/edit/:locationId',

  SPENDING: '/spending',
  ANALYTICS: '/analytics',
  PAYMENT_SUCCESS: '/payment/success',
  PAYMENT_FAIL: '/payment/fail',
  NOT_FOUND: '/404',
};

export default routes;
