import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Table from 'components/Table';
import Search from '../../components/Search';
import Loader from '../../components/Loader/Loader';
import LoginInfo from '../../components/LoginInfo/LoginInfo';
import PageTitle from '../../components/PageTitle/PageTitle';

import { interpolate, isEmpty as isStringEmpty } from '../../utils/string';
import { fetchCompanyOptions, fetchUserOptions } from '../../service/options';

import ROUTES from '../../constants/routes';

const MyDeals = (props) => {
  const { getCustomerList, customers, isLoading } = props;

  const initialFilters = {
    id: null,
  };

  const [appliedFilters, setAppliedFilters] = useState(initialFilters);

  const updateAppliedFilter = (key, value) => {
    setAppliedFilters((prevFilter) => ({
      ...prevFilter,
      [key]: value,
    }));
  };

  useEffect(() => {
    getCustomerList(appliedFilters);
  }, [appliedFilters]);

  const fetchByUserName = useCallback(
    (searchText) =>
      fetchUserOptions({
        fullName: searchText,
      }),
    []
  );

  const fetchCompanyByName = useCallback(
    (searchText) =>
      fetchCompanyOptions({
        name: searchText,
      }),
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'fullName',
      },
      {
        Header: 'Last Logged In',
        accessor: 'lastLoggedIn',
        Cell: (cellInfo) => {
          const value = cellInfo.cell.value;

          if (isStringEmpty(value)) return '-';

          return dayjs(value).format('MMMM D, YYYY h:mm A');
        },
      },
      {
        Header: 'Date Created',
        accessor: 'createdAt',
        Cell: (cellInfo) => {
          const value = cellInfo.cell.value;

          if (isStringEmpty(value)) return '-';
          return dayjs(value).format('DD/MM/YYYY');
        },
      },
      {
        id: 'action',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original = {} } }) => {
          const editUrl = interpolate(ROUTES.EDIT_CUSTOMER, { id: original?._id || '' });

          return (
            <Link className="text-decoration-none link-button color-windows-blue" to={editUrl}>
              Edit
            </Link>
          );
        },
      },
    ],
    []
  );

  const renderTable = () => {
    if (isLoading) {
      return <Loader />;
    }

    return <Table data={customers} columns={columns} />;
  };

  return (
    <div className="w-100">
      <div className="d-flex justify-content-between mb-4">
        <PageTitle title="Customers" />
        <LoginInfo />
      </div>

      <div className="row">
        <div className="col-2">
          <Search
            label="Name:"
            placeholder="Search By Name"
            fetchOptionsFunction={fetchByUserName}
            onChange={(selectedOption) => updateAppliedFilter('id', selectedOption?.value)}
            isClearable
          />
        </div>
        <div className="col-2">
          <Search
            label="Company:"
            placeholder="Search By Company"
            fetchOptionsFunction={fetchCompanyByName}
            onChange={(selectedOption) => updateAppliedFilter('companyId', selectedOption?.value)}
            isClearable
          />
        </div>
      </div>

      <div className="table-container">{renderTable()}</div>
    </div>
  );
};

MyDeals.propTypes = {
  getCustomerList: PropTypes.func,
  customers: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default MyDeals;
