import {
  ADMIN_LOGIN,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_FAIL,
  GET_ADMIN_INFO,
  GET_ADMIN_INFO_SUCCESS,
  GET_ADMIN_INFO_FAIL,
  CLEAR_AUTH_STATE,
} from '../types';

import * as authService from '../../service/auth';
import * as adminService from '../../service/admin';

export const getCurrentUserInfo = () => {
  return (dispatch) => {
    const onGetCustomerInfoSuccess = (response) =>
      dispatch({
        type: GET_ADMIN_INFO_SUCCESS,
        payload: response,
      });

    const onGetCustomerInfoError = (error) => dispatch({ type: GET_ADMIN_INFO_FAIL, payload: error });

    dispatch({
      type: GET_ADMIN_INFO,
    });

    return adminService.getCurrentUserInfo().then(onGetCustomerInfoSuccess).catch(onGetCustomerInfoError);
  };
};

export const login = (email, password) => {
  return (dispatch) => {
    const onLoginSuccess = ({ user }) =>
      dispatch({
        type: ADMIN_LOGIN_SUCCESS,
        payload: user,
      });

    const onLoginError = (error) => dispatch({ type: ADMIN_LOGIN_FAIL, payload: error });

    dispatch({
      type: ADMIN_LOGIN,
    });

    return authService.login(email, password).then(onLoginSuccess).catch(onLoginError);
  };
};

export const clearAuthState = () => {
  return (dispatch) => {
    authService.clearAuthTokens();

    dispatch({
      type: CLEAR_AUTH_STATE,
    });
  };
};
