import React, { useState } from 'react';

import Toast from 'components/Toast';
import CategoryForm from 'screens/Categories/CategoryForm/CategoryForm';

import { addCategory } from 'service/category';
import { handleError } from 'utils/errorHandler';

const initialFormData = {
  backgroundColor: '',
  color: '',
  icon: '',
  iconLibrary: 'MaterialCommunityIcons', // TODO: Remove once multiple icon libraries is handled by the app
  subTitle: '',
  title: '',
};

const AddCategory = (props) => {
  const [errorMessage, setErrorMessage] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState(initialFormData);

  const onClear = () => {
    setFormData(initialFormData);
    setErrorMessage({});
  };

  const onSubmit = async () => {
    setIsSubmitting(true);

    try {
      await addCategory(formData);
      Toast.success({ title: 'Success', message: 'Category created successfully!' });

      setFormData({ ...initialFormData });
    } catch (err) {
      handleError(err);
    }

    setIsSubmitting(false);
  };

  return (
    <CategoryForm
      pageTitle="Add new Deals"
      isSubmitting={isSubmitting}
      formData={formData}
      errorMessage={errorMessage}
      onClear={onClear}
      onSubmit={onSubmit}
      setFormData={setFormData}
      setErrorMessage={setErrorMessage}
    />
  );
};

AddCategory.propTypes = {};

export default AddCategory;
