import { connect } from 'react-redux';

import { getCustomerList } from '../../redux/actions/customers';

import Component from './Customers';

const mapStateToProps = (state, props) => {
  return {
    isLoading: state.customer.isLoading,
    customers: state.customer.customers,
  };
};

const mapDispatchToProps = { getCustomerList };

export default connect(mapStateToProps, mapDispatchToProps)(Component);
