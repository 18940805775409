import { useEffect, useState } from 'react';

import { handleError } from 'utils/errorHandler';

const useFetchOptions = ({ fetchFunction, searchText }) => {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    setError(null);
    setOptions([]);

    const fetchOptions = async () => {
      try {
        const fetchedOptions = await fetchFunction(searchText);
        setOptions(fetchedOptions);
        setIsLoading(false);
      } catch (error) {
        handleError(error);
        setError(error);
        setIsLoading(false);
      }
    };

    fetchOptions();
  }, [fetchFunction, searchText]);

  return { isLoading, error, options };
};

export default useFetchOptions;
