import 'react-image-crop/dist/ReactCrop.css';

import Modal from 'react-modal';
import PropTypes from 'prop-types';
import ReactCrop from 'react-image-crop';
import React, { useState, useRef, useCallback } from 'react';

import Button from 'components/Button/Button';

import { getCroppedImage } from './ImageCropUtil';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
    padding: '30px 35px',
    overflowY: 'auto',
    maxHeight: '96vh',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
};

function ImageCropModal(props) {
  const { open, onModalClose, image, aspectRatio = 16 / 9, onImageCropComplete } = props;

  const imgRef = useRef(null);
  const [crop, setCrop] = useState({ unit: '%', width: 100, aspect: aspectRatio });

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const onImageCropped = async () => {
    const width = 640;
    const height = width / aspectRatio;

    const croppedImg = await getCroppedImage(imgRef.current, crop, 'fileName', width, height);

    onModalClose();
    onImageCropComplete([croppedImg]);
  };

  return (
    <Modal isOpen={open} style={customStyles} ariaHideApp={false} contentLabel="Edit Billing Details Modal">
      <h2 className="fs-26px fw-600 mt-0">Crop you Image</h2>

      <div style={{ width: 750, maxHeight: 650, overflowY: 'auto' }}>
        <ReactCrop minWidth={300} src={image} onImageLoaded={onLoad} crop={crop} onChange={(c) => setCrop(c)} />
      </div>

      <div className="d-flex justify-content-end mt-4 ml-3 mr-3">
        <Button
          onClick={onModalClose}
          title="Cancel"
          className="mr-4 pl-4 pr-4 fs-16px border-windows-blue color-windows-blue"
        />
        <Button
          title="Save"
          className="pl-4 pr-4 fs-16px bg-windows-blue border-windows-blue color-white"
          onClick={onImageCropped}
        />
      </div>
    </Modal>
  );
}

ImageCropModal.propTypes = {
  aspectRatio: PropTypes.number,
  image: PropTypes.string,
  onImageCropComplete: PropTypes.func,
  onModalClose: PropTypes.func,
  open: PropTypes.bool,
};

export default ImageCropModal;
