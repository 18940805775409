import { connect } from 'react-redux';

import { getLocationList } from 'redux/actions/location';
import { getCompanyList } from '../../../../redux/actions/company';

import { getLocationOptions } from 'redux/selectors/location';
import { getCompanyOptions } from '../../../../redux/selectors/company';

import Component from './DealsForm';

const mapStateToProps = (state, props) => {
  return {
    isLoadingLocation: state.location.isLoading,
    isLoadingCompany: state.company.gettingAllCompanyList,

    locations: getLocationOptions(state),
    companies: getCompanyOptions(state),
  };
};

const mapDispatchToProps = { getLocationList, getCompanyList };

export default connect(mapStateToProps, mapDispatchToProps)(Component);
