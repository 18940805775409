import { connect } from 'react-redux';

import { getAreaList } from '../../../../redux/actions/area';
import { getCompanyList } from '../../../../redux/actions/company';
import { getCategoryList } from '../../../../redux/actions/category';

import { getAreaOptions } from '../../../../redux/selectors/area';
import { getCompanyOptions } from '../../../../redux/selectors/company';
import { getCategoryOptions } from '../../../../redux/selectors/category';

import Component from './LocationForm';

const mapStateToProps = (state, props) => {
  return {
    isLoadingArea: state.area.isLoading,
    isLoadingCategory: state.category.isLoading,
    isLoadingCompany: state.company.gettingAllCompanyList,
    areas: getAreaOptions(state),
    companies: getCompanyOptions(state),
    categories: getCategoryOptions(state),
  };
};

const mapDispatchToProps = { getAreaList, getCompanyList, getCategoryList };

export default connect(mapStateToProps, mapDispatchToProps)(Component);
