import { connect } from 'react-redux';

import { getDealList } from '../../redux/actions/deal';

import Component from './Deals';

const mapStateToProps = (state, props) => {
  return {
    isLoading: state.deal.isLoading,
    deals: state.deal.deals,
  };
};

const mapDispatchToProps = { getDealList };

export default connect(mapStateToProps, mapDispatchToProps)(Component);
