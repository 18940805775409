import * as endpoints from '../constants/endpoints';

import http from '../utils/http';
import { interpolate } from 'utils/string';
import { formatLocationDataForAPI } from 'screens/Locations/components/LocationUtil';

/**
 * Get a Single Location Detail.
 *
 * @param {string} locationId
 *
 * @returns {object}
 */
export const getLocation = async (locationId) => {
  const url = interpolate(endpoints.LOCATION_SINGLE, { locationId });
  const { data } = await http.get(url);

  return data;
};

export const addLocation = async (location) => {
  const formattedlocation = formatLocationDataForAPI(location);

  const response = await http.post(endpoints.LOCATION, {
    body: formattedlocation,
  });

  return response.data;
};

/**
 * Update Location.
 *
 * @param {string} locationId
 * @param {object} body
 *
 * @returns {object}
 */
export async function updateLocationData(locationId, body) {
  const formattedlocation = formatLocationDataForAPI(body);

  const url = interpolate(endpoints.LOCATION_SINGLE, { locationId });
  const { data } = await http.patch(url, { body: formattedlocation });

  return data;
}
