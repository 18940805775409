import { useEffect, useState } from 'react';

const useDebounceValue = (input, timeOut = 500) => {
  const [value, setValue] = useState(input);

  useEffect(() => {
    const handler = setTimeout(() => {
      setValue(input);
    }, timeOut);

    return () => {
      clearTimeout(handler);
    };
  }, [input, timeOut]);

  return value;
};

export default useDebounceValue;
