export const GET_COMPANY_LIST = 'GET_COMPANY_LIST';
export const GET_COMPANY_LIST_SUCCESS = 'GET_COMPANY_LIST_SUCCESS';
export const GET_COMPANY_LIST_FAIL = 'GET_COMPANY_LIST_FAIL';

export const GET_LOCATION_LIST = 'GET_LOCATION_LIST';
export const GET_LOCATION_LIST_SUCCESS = 'GET_LOCATION_LIST_SUCCESS';
export const GET_LOCATION_LIST_FAIL = 'GET_LOCATION_LIST_FAIL';

export const GET_CATEGORY_LIST = 'GET_CATEGORY_LIST';
export const GET_CATEGORY_LIST_SUCCESS = 'GET_CATEGORY_LIST_SUCCESS';
export const GET_CATEGORY_LIST_FAIL = 'GET_CATEGORY_LIST_FAIL';

export const GET_AREA_LIST = 'GET_AREA_LIST';
export const GET_AREA_LIST_SUCCESS = 'GET_AREA_LIST_SUCCESS';
export const GET_AREA_LIST_FAIL = 'GET_AREA_LIST_FAIL';

export const GET_DEAL_LIST = 'GET_DEAL_LIST';
export const GET_DEAL_LIST_SUCCESS = 'GET_DEAL_LIST_SUCCESS';
export const GET_DEAL_LIST_FAIL = 'GET_DEAL_LIST_FAIL';

export const CLEAR_AUTH_STATE = 'CLEAR_AUTH_STATE';

export const ADMIN_LOGIN = 'ADMIN_LOGIN';
export const ADMIN_LOGIN_SUCCESS = 'ADMIN_LOGIN_SUCCESS';
export const ADMIN_LOGIN_FAIL = 'ADMIN_LOGIN_FAIL';

export const GET_ADMIN_INFO = 'GET_ADMIN_INFO';
export const GET_ADMIN_INFO_SUCCESS = 'GET_ADMIN_INFO_SUCCESS';
export const GET_ADMIN_INFO_FAIL = 'GET_ADMIN_INFO_FAIL';

export const GET_USER_LIST = 'GET_USER_LIST';
export const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS';
export const GET_USER_LIST_FAIL = 'GET_USER_LIST_FAIL';

export const GET_CUSTOMER_LIST = 'GET_CUSTOMER_LIST';
export const GET_CUSTOMER_LIST_SUCCESS = 'GET_CUSTOMER_LIST_SUCCESS';
export const GET_CUSTOMER_LIST_FAIL = 'GET_CUSTOMER_LIST_FAIL';
