import { connect } from 'react-redux';

import { login } from '../../../redux/actions/auth';

import Component from './Login';

const mapStateToProps = (state, props) => {
  return {
    isSubmitting: state.auth.isLoading,
    loginError: state.auth.error,
  };
};

const mapDispatchToProps = { login };

export default connect(mapStateToProps, mapDispatchToProps)(Component);
