import { connect } from 'react-redux';

import Component from './AddDeal';

const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
