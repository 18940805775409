import http from '../../utils/http';
import * as endpoints from '../../constants/endpoints';
import { GET_AREA_LIST, GET_AREA_LIST_FAIL, GET_AREA_LIST_SUCCESS } from '../types';

export const getAreaList = () => {
  return function (dispatch) {
    dispatch({ type: GET_AREA_LIST });

    return http
      .get(endpoints.AREA)
      .then((res) => dispatch(saveAreas(res.data)))
      .catch((err) => dispatch(errorOnFetchAreas(err)));
  };
};

const saveAreas = (payload) => ({
  type: GET_AREA_LIST_SUCCESS,
  payload,
});

const errorOnFetchAreas = (payload) => ({
  type: GET_AREA_LIST_FAIL,
  payload,
});
