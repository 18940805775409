/**
 * @param {HTMLImageElement} image - Image File Object
 * @param {Object} crop - crop Object
 * @param {String} fileName - Name of the returned file in Promise
 * @param {number} imageWidth - {optional} default: Crop width will be used
 * @param {number} imageHeight - {optional} default: Crop height will be used
 * @param {boolean} downloadImageAfterCrop - {optional} default: false
 *
 * @return {Promise}
 */
export const getCroppedImage = (image, crop, fileName, imageWidth, imageHeight, downloadImageAfterCrop = false) => {
  const width = imageWidth || crop.width;
  const height = imageHeight || crop.height;

  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext('2d');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    width,
    height
  );

  // As Base64 string
  const base64Image = canvas.toDataURL('image/png');

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        blob.name = fileName;
        const file = new File([blob], `${fileName}.png`, { type: 'image/png', lastModified: new Date().getTime() });

        if (downloadImageAfterCrop) {
          const previewUrl = window.URL.createObjectURL(blob);

          const anchor = document.createElement('a');
          anchor.download = 'cropPreview.png';
          anchor.href = URL.createObjectURL(blob);
          anchor.click();

          window.URL.revokeObjectURL(previewUrl);
        }

        resolve({ data_url: base64Image, file });
      },
      'image/png',
      1
    );
  });
};
