import { GET_DEAL_LIST, GET_DEAL_LIST_FAIL, GET_DEAL_LIST_SUCCESS } from '../types';

const initState = {
  deals: [],
  error: null,
  isLoading: false,
};

export default (state = initState, action) => {
  switch (action.type) {
    case GET_DEAL_LIST: {
      return { ...state, isLoading: true };
    }

    case GET_DEAL_LIST_SUCCESS: {
      const { payload } = action;

      return { ...state, isLoading: false, deals: payload, error: null };
    }

    case GET_DEAL_LIST_FAIL: {
      const { payload } = action;

      return { ...state, isLoading: false, deals: [], error: payload };
    }

    default:
      return state;
  }
};
