import http from '../../utils/http';
import * as endpoints from '../../constants/endpoints';
import { GET_CATEGORY_LIST, GET_CATEGORY_LIST_FAIL, GET_CATEGORY_LIST_SUCCESS } from '../types';

export const getCategoryList = () => {
  return function (dispatch) {
    dispatch({ type: GET_CATEGORY_LIST });

    return http
      .get(endpoints.CATEGORIES)
      .then((res) => dispatch(saveCategorys(res.data)))
      .catch((err) => dispatch(errorOnFetchCategorys(err)));
  };
};

const saveCategorys = (payload) => ({
  type: GET_CATEGORY_LIST_SUCCESS,
  payload,
});

const errorOnFetchCategorys = (payload) => ({
  type: GET_CATEGORY_LIST_FAIL,
  payload,
});
