import { connect } from 'react-redux';

import { getCategoryList } from '../../redux/actions/category';

import Component from './Categories';

const mapStateToProps = (state, props) => ({
  isLoading: state.category.isLoading,
  categories: state.category.categories,
});

const mapDispatchToProps = { getCategoryList };

export default connect(mapStateToProps, mapDispatchToProps)(Component);
