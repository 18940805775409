import { GET_COMPANY_LIST, GET_COMPANY_LIST_SUCCESS, GET_COMPANY_LIST_FAIL } from '../types';

const initState = {
  companies: [],
  gettingAllCompanyList: false,
  gettingAllCompanyListSuccess: false,
  gettingAllCompanyListFailure: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case GET_COMPANY_LIST: {
      return {
        ...state,
        gettingAllCompanyList: true,
        gettingAllCompanyListSuccess: false,
        gettingAllCompanyListFailure: null,
      };
    }

    case GET_COMPANY_LIST_SUCCESS: {
      const { payload } = action;

      return {
        ...state,
        companies: payload,
        gettingAllCompanyList: false,
        gettingAllCompanyListSuccess: true,
        gettingAllCompanyListFailure: null,
      };
    }

    case GET_COMPANY_LIST_FAIL: {
      const { payload } = action;

      return {
        ...state,
        companies: [],
        gettingAllCompanyList: false,
        gettingAllCompanyListSuccess: false,
        gettingAllCompanyListFailure: payload,
      };
    }

    default:
      return state;
  }
};
