import React, { useState } from 'react';

import Toast from 'components/Toast';
import DealsForm from 'screens/Deals/components/DealsForm';

import { handleError } from 'utils/errorHandler';
import * as dealService from '../../service/deal';

const initialFormData = {
  title: '',
  companyId: {
    label: '',
    value: '',
  },
  days: [
    {
      label: '',
      value: '',
    },
  ],
  locationIds: [
    {
      label: '',
      value: '',
    },
  ],
  timeSlot: '',
  discount: {
    price: '',
    discountedPrice: '',
  },
  claimCodes: {
    passphase: '',
  },
  image: [],
};

const AddDeal = (props) => {
  const [errorMessage, setErrorMessage] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState(initialFormData);

  const onClear = () => {
    setFormData(initialFormData);
    setErrorMessage({});
  };

  const onSubmit = async () => {
    setIsSubmitting(true);

    try {
      await dealService.addDeal(formData);
      Toast.success({ title: 'OK', message: 'Deal added successfully!' });

      setFormData({ ...initialFormData });
    } catch (err) {
      handleError(err);
    }

    setIsSubmitting(false);
  };

  return (
    <DealsForm
      pageTitle="Add new Deals"
      isSubmitting={isSubmitting}
      formData={formData}
      errorMessage={errorMessage}
      onClear={onClear}
      onSubmit={onSubmit}
      setFormData={setFormData}
      setErrorMessage={setErrorMessage}
    />
  );
};

AddDeal.propTypes = {};

export default AddDeal;
