import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import { isEmpty as isEmptyObject } from 'utils/object';
import { getCurrentUserInfo, clearAuthState } from './redux/actions/auth';

import ROUTES from './constants/routes';
import { GET_ADMIN_INFO_FAIL } from './redux/types';

import Loader from './components/Loader';

const AuthenticatedRoutes = (props) => {
  const history = useHistory();
  const { getCurrentUserInfo, clearAuthState, isFetchingCustomerInfo, admin = {}, children } = props;

  useEffect(async () => {
    const response = await getCurrentUserInfo();

    if (response.type === GET_ADMIN_INFO_FAIL) {
      clearAuthState();
      history.replace(ROUTES.AUTH.LOGIN);
    }
  }, [getCurrentUserInfo, clearAuthState]);

  /**
   * Don't Move isFetching -> Loader and Children as in If Else.
   * If Admin data is fetching loader will be shown,
   * if there is valid customer data other valid authenticated route will be shown.
   *
   * The main problem lies here that, as children is main component mounting and unmounting those componet
   * has higher consequences and high render cost.
   *
   */
  return (
    <>
      {isFetchingCustomerInfo && <Loader />}
      {!isEmptyObject(admin) && children}
    </>
  );
};

AuthenticatedRoutes.propTypes = {
  children: PropTypes.any,
  clearAuthState: PropTypes.func.isRequired,
  admin: PropTypes.object,
  getCurrentUserInfo: PropTypes.func.isRequired,
  isFetchingCustomerInfo: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const admin = state.auth?.admin;

  return {
    admin,
    isFetchingCustomerInfo: state.auth.isLoading,
    error: state.auth.error,
  };
};

const mapDispatchToProps = { getCurrentUserInfo, clearAuthState };

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedRoutes);
