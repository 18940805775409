import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import InputLabel from 'components/InputLabel/InputLabel';

const TextInput = React.forwardRef((props, ref) => {
  const {
    label,
    placeholder = '',
    onChange = () => {},
    value,
    Icon,
    className,
    errorMessage,
    type = 'text',
    name,
    disabled = false,
  } = props;

  return (
    <div
      className={classnames('text-input', {
        [className]: className,
      })}
    >
      {label && <InputLabel label={label} />}
      <div
        className={classnames('text-input__input', {
          'text-input__input--no-icon': !Icon,
        })}
      >
        {Icon && <Icon className="text-input__input__icon" />}
        <input
          ref={ref}
          name={name}
          onChange={(event) => onChange(name, event.target.value)}
          type={type}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
        />
      </div>
      {errorMessage && <div className="text-input__info">{errorMessage}</div>}
    </div>
  );
});

TextInput.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  Icon: PropTypes.elementType,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default TextInput;
