import 'izitoast/dist/css/iziToast.css';

import iziToast from 'izitoast';

iziToast.settings({
  timeout: 4000,
  resetOnHover: true,
  progressBar: false,
  transitionIn: 'fadeInUp',
  transitionOut: 'fadeOutDown',
  position: 'bottomCenter',
  displayMode: 'replace',
});

export const success = ({ title, message, extraParams = {} }) => {
  iziToast.success({
    title,
    message,
    ...extraParams,
  });
};

export const error = ({ title, message, extraParams = {} }) => {
  iziToast.error({
    title,
    message,
    ...extraParams,
  });
};

export const warning = ({ title = '', message, extraParams = {} }) => {
  iziToast.warning({
    title,
    message,
    ...extraParams,
  });
};

export const info = ({ title = '', message, extraParams = {} }) => {
  iziToast.info({
    title,
    message,
    ...extraParams,
  });
};
