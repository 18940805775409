export const CATEGORIES = '/v1/admin/categories';
export const CATEGORY = '/v1/admin/categories/:id';

export const LOGIN_ADMIN = '/v1/admin/auth/login';
export const REFRESH_ACCESS_TOKEN = '/v1/admin/auth/refresh-access-token';

export const USER = '/v1/admin/users';
export const CUSTOMERS = '/v1/admin/customers';
export const CUSTOMER = '/v1/admin/customers/:customerId';

export const UPDATE_USER = '/v1/admin/admins/:userId';
export const FETCH_CURRENT_USER = '/v1/admin/admins/me';

export const LOCATION = '/v1/admin/locations';
export const LOCATION_SINGLE = '/v1/admin/locations/:locationId';

export const DEAL = '/v1/admin/deals';
export const DEAL_SINGLE = '/v1/admin/deals/:dealId';

export const AREA = '/v1/admin/areas';
export const COMPANY = '/v1/admin/companies';

export const FILE_UPLOAD = '/v1/admin/file/upload';
export const BOOST_PRICE = '/v1/admin/payment/boost-price';
export const CHECKOUT_SESSION = '/v1/admin/payment/checkout-session';

export const USER_OPTIONS = '/v1/admin/options/users';
export const COMPANY_OPTIONS = '/v1/admin/options/companies';
export const CUSTOMER_OPTIONS = '/v1/admin/options/customers';
export const LOCATION_OPTIONS = '/v1/admin/options/locations';
export const AREA_OPTIONS = '/v1/admin/options/areas';
export const DEAL_OPTIONS = '/v1/admin/options/deals';
