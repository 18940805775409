import dayjs from 'dayjs';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FaInfoCircle } from 'react-icons/fa';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Table from 'components/Table';
import Search from '../../components/Search';
import Loader from '../../components/Loader/Loader';
import LoginInfo from '../../components/LoginInfo/LoginInfo';
import PageTitle from '../../components/PageTitle/PageTitle';
import SelectInput from '../../components/SelectInput/SelectInput';

import ROUTES from '../../constants/routes';
import { DAYS, DEAL_STATUSES } from '../../constants/appConstant';

import { changeDealStatus } from '../../service/deal';
import { interpolate, isEmpty as isStringEmpty } from '../../utils/string';
import { fetchDealOptions, fetchLocationOptions } from '../../service/options';

const MyDeals = (props) => {
  const { getDealList, deals, isLoading } = props;
  const tableData = deals;

  const initialFilters = {
    id: null,
    locationId: null,
    day: null,
    status: null,
  };

  const [appliedFilters, setAppliedFilters] = useState(initialFilters);

  const updateAppliedFilter = (key, value) => {
    setAppliedFilters((prevFilter) => ({
      ...prevFilter,
      [key]: value,
    }));
  };

  useEffect(() => {
    getDealList(appliedFilters);
  }, [appliedFilters]);

  const fetchByDealName = useCallback(
    (searchText) =>
      fetchDealOptions({
        title: searchText,
      }),
    []
  );

  const fetchLocationByName = useCallback(
    (searchText) =>
      fetchLocationOptions({
        name: searchText,
      }),
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Deal',
        accessor: 'title',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => (
          <div className="d-flex">
            <p className="m-0">{value}</p>
            <button className="link-button">
              <FaInfoCircle color="#007aff" />
            </button>
          </div>
        ),
      },
      {
        Header: 'Date Created',
        accessor: 'createdAt',
        Cell: (cellInfo) => {
          const value = cellInfo.cell.value;

          if (isStringEmpty(value)) return '-';
          return dayjs(value).format('DD/MM/YYYY');
        },
      },
      {
        Header: 'Location',
        accessor: 'location.name',
        id: 'location',
      },
      {
        Header: 'Status',
        accessor: 'status',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value, data }) => {
          const dealData = data[0];
          // eslint-disable-next-line react/prop-types
          const label = value.toUpperCase();
          return (
            <div>
              <Select
                options={DEAL_STATUSES}
                name="status"
                value={{ value, label }}
                onChange={(selectedOption) => {
                  // eslint-disable-next-line react/prop-types
                  onStatusChange(dealData._id, selectedOption);
                }}
                closeMenuOnSelect={false}
                isClearable={false}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: 130,
                    fontSize: 12,
                    color:
                      value === 'approved'
                        ? 'green'
                        : value === 'pending'
                        ? '#2153eb'
                        : value === 'cancelled'
                        ? '#da383f'
                        : 'red',
                    backgroundColor: 'none',
                    border: 'none',
                  }),
                  menu: (provided) => ({
                    ...provided,
                    width: 130,
                    fontSize: 12,
                  }),
                }}
              />
            </div>
          );
        },
      },
      {
        id: 'action',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original = {} } }) => {
          const editUrl = interpolate(ROUTES.EDIT_DEAL, { dealId: original?._id || '' });

          return (
            <Link className="text-decoration-none link-button color-windows-blue" to={editUrl}>
              Edit
            </Link>
          );
        },
      },
    ],
    []
  );

  const onStatusChange = async (dealId, selectedOption) => {
    if (confirm(`Are you sure You want to Change the status to ${selectedOption.label}?`)) {
      await changeDealStatus(dealId, { status: selectedOption.value });
      setAppliedFilters({});
    }
  };

  const renderTable = () => {
    if (isLoading) {
      return <Loader />;
    }

    return <Table data={tableData} columns={columns} />;
  };

  return (
    <div className="w-100">
      <div className="d-flex justify-content-between mb-4">
        <PageTitle title="Deals" />
        <LoginInfo />
      </div>

      <div className="row">
        <div className="col-2">
          <Search
            label="Deals:"
            placeholder="Search By Deal"
            fetchOptionsFunction={fetchByDealName}
            onChange={(selectedOption) => updateAppliedFilter('id', selectedOption?.value)}
            isClearable
          />
        </div>
        <div className="col-2">
          <Search
            label="Location:"
            placeholder="Search By Location"
            fetchOptionsFunction={fetchLocationByName}
            onChange={(selectedOption) => updateAppliedFilter('locationId', selectedOption?.value)}
            isClearable
          />
        </div>
        <div className="col-2">
          <SelectInput
            className="mb-4"
            label="Days:"
            placeholder="Search By Days"
            options={DAYS}
            isClearable
            name="day"
            onChange={(_, selectedOption) => updateAppliedFilter('day', selectedOption?.value)}
            hideDropdownIndicator
          />
        </div>
        <div className="col-2">
          <SelectInput
            className="mb-4"
            label="Status:"
            options={DEAL_STATUSES}
            placeholder="Search By Status"
            isClearable
            name="day"
            onChange={(_, selectedOption) => updateAppliedFilter('status', selectedOption?.value)}
            hideDropdownIndicator
          />
        </div>
      </div>

      <div className="table-container">{renderTable()}</div>

      <div className="d-flex justify-content-between deals-info">
        <div className="d-flex align-items-center">
          <FaInfoCircle color="#007aff" /> &nbsp; Click to get more information.
        </div>
        <Link className="text-decoration-none" to={ROUTES.CREATE_DEAL}>
          <button className="d-flex align-items-center link-button">
            Add a new Deal &nbsp;
            <div className="d-flex align-items-center justify-content-center add-deal">+</div>
          </button>
        </Link>
      </div>
    </div>
  );
};

MyDeals.propTypes = {
  getDealList: PropTypes.func,
  deals: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default MyDeals;
