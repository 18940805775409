import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import { FaPaintBrush } from 'react-icons/fa';

import InputLabel from 'components/InputLabel/InputLabel';
import ClickAwayListener from 'components/ClickAwayListener/ClickAwayListener';

import { hexToHSL } from 'utils/color';

const ColorPicker = (props) => {
  const { label, value, onChange } = props;
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);

  const extractColor = (hexValue) => {
    if (!hexValue) return 'black';

    const { l: lightness } = hexToHSL(hexValue);

    return lightness > 0.5 ? 'black' : 'white';
  };

  return (
    <div>
      <InputLabel label={label} />
      <div className="d-flex">
        <div
          title="Pick Color"
          style={{
            backgroundColor: value,
            color: extractColor(value),
          }}
          className="color-picker-preview"
          onClick={() => setIsColorPickerOpen(true)}
        >
          <FaPaintBrush />
        </div>

        {isColorPickerOpen && (
          <ClickAwayListener onClickAway={() => setIsColorPickerOpen(false)}>
            <div>
              <SketchPicker color={value} onChange={({ hex }) => onChange(hex)} />
            </div>
          </ClickAwayListener>
        )}
      </div>
    </div>
  );
};

ColorPicker.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

export default ColorPicker;
