import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { CgOrganisation } from 'react-icons/cg';
import { FaTag, FaMapMarkerAlt, FaHeadset, FaPhone, FaCalendarAlt, FaClock } from 'react-icons/fa';

import ClaimDeal from '../ClaimDeal/ClaimDeal';
import DealImgUpload from '../DealImgUpload/DealImgUpload';

import Button from '../../../../components/Button/Button';
import Loader from '../../../../components/Loader/Loader';
import TextInput from '../../../../components/TextInput/TextInput';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import SupportLink from '../../../../components/SupportLink/SupportLink';
import SelectInput from '../../../../components/SelectInput/SelectInput';

import { convert12hrTo24hr } from '../../../../utils/date';
import { isEmpty as isArrayEmpty } from '../../../../utils/array';
import { isEmpty as isStringEmpty } from '../../../../utils/string';
import { isEmpty as isObjectEmpty, get as objectGet } from '../../../../utils/object';

import regex from 'constants/regex';
import { DAYS } from 'constants/appConstant';

const isValidTimeSlotFormat = (businessHours) => {
  if (!regex.time.test(businessHours)) return false;

  const [openingTime, closingTime] = businessHours.trim().split('-');

  const openingTime24 = convert12hrTo24hr(openingTime);
  const closingTime24 = convert12hrTo24hr(closingTime);

  return openingTime24 < closingTime24;
};

function MyDealsForm(props) {
  const {
    pageTitle = '',
    formData = {},
    setFormData,
    errorMessage = {},
    setErrorMessage,
    onClear,
    onSubmit,
    companies,
    locations,
    isSubmitting,
    isGettingData,
    isLoadingCompany,
    isLoadingLocation,
    getCompanyList,
    getLocationList,
  } = props;

  useEffect(() => {
    getCompanyList();
    getLocationList();
  }, [getCompanyList, getLocationList]);

  const handleOnSubmit = () => {
    if (!isFormValidated()) {
      return;
    }

    onSubmit();
  };

  const isFormValidated = () => {
    const requiredFields = {
      title: 'Name',
      companyId: 'Company',
      locationIds: 'Location',
      timeSlot: 'Time Slot',
      days: 'Days',
      'claimCodes.passphase': 'PassPhrase',
      'discount.price': 'Initial Price',
      'discount.discountedPrice': 'Discount Price',
    };

    let isValidated = true;
    let validatedMessage = {};

    if (!formData?.image?.[0]?.data_url) {
      isValidated = false;

      validatedMessage = {
        ...validatedMessage,
        image: 'Image is required',
      };
    }

    Object.keys(requiredFields).forEach((fieldName) => {
      const value = objectGet(formData, fieldName);
      if ((Array.isArray(value) && isArrayEmpty(value)) || isStringEmpty(value)) {
        isValidated = false;

        validatedMessage = {
          ...validatedMessage,
          [fieldName]: `${requiredFields[fieldName]} is required`,
        };
      }
    });

    if (!isValidated) {
      setErrorMessage({
        ...errorMessage,
        ...validatedMessage,
      });
      return false;
    }

    return true;
  };

  const onTextInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === 'timeSlot' && !isValidTimeSlotFormat(value)) {
      setErrorMessage({
        ...errorMessage,
        [name]: 'Time Slot should be in 9am - 4pm format',
      });

      return;
    }

    setErrorMessage({});
  };

  const onPhraseChange = (_, phrase) => {
    onTextInputChange('claimCodes', {
      ...formData.claimCodes,
      passphase: phrase,
    });
  };

  if (isLoadingLocation || isLoadingCompany || isSubmitting || isGettingData) {
    return <Loader />;
  }

  return (
    <div className="w-100">
      <div className="d-flex justify-content-between mb-4">
        <PageTitle title={pageTitle} />
      </div>

      <div className="row no-gutters mb-4">
        <div className="col-6 pr-4 pb-4 no-gutters">
          <div className="row no-gutters">
            <SelectInput
              className="col-6 pr-4 mb-4"
              label="Company:"
              placeholder="Select the Company"
              Icon={CgOrganisation}
              name="companyId"
              options={companies}
              optionKey="_id"
              optionLabel="name"
              value={formData.companyId}
              onChange={onTextInputChange}
              errorMessage={errorMessage.companyId}
            />
          </div>

          <div className="row col-12 no-gutters">
            <div className="col-6 pr-4">
              <TextInput
                className="mb-4"
                label="Name of the offer:"
                placeholder="1/2 Price Drinks"
                Icon={FaTag}
                name="title"
                value={formData.title}
                onChange={onTextInputChange}
                errorMessage={errorMessage.title}
              />
              <SelectInput
                isMulti
                className="mb-4"
                label="Select a location:"
                placeholder="Select a location"
                Icon={FaMapMarkerAlt}
                name="locationIds"
                options={locations}
                value={formData.locationIds}
                onChange={onTextInputChange}
                errorMessage={errorMessage.locationIds}
              />
            </div>
            <div className="col-6 ">
              <SelectInput
                isMulti
                className="mb-4"
                label="Select days:"
                placeholder="Select days"
                Icon={FaCalendarAlt}
                name="days"
                options={DAYS}
                value={formData.days}
                onChange={onTextInputChange}
                errorMessage={errorMessage.days}
              />
              <TextInput
                className="mb-4"
                label="Timeslot:"
                placeholder="6pm - 7pm"
                name="timeSlot"
                Icon={FaClock}
                value={formData.timeSlot}
                onChange={onTextInputChange}
                errorMessage={errorMessage.timeSlot}
              />
            </div>
          </div>

          <div className="row no-gutters justify-content-end mt-5">
            <div>
              <Button onClick={onClear} title="CLEAR" className="topup-btn pl-5 pr-5 mr-5" />
              <Button
                title="SUBMIT"
                className="submit-btn pl-5 pr-5"
                disabled={!isObjectEmpty(errorMessage)}
                onClick={handleOnSubmit}
              />
            </div>
          </div>
        </div>

        <div className="col-6">
          <ClaimDeal
            className="mb-4"
            errorMessage={errorMessage['claimCodes.passphase']}
            phrase={formData.claimCodes.passphase}
            onPhraseChange={onPhraseChange}
          />
          <DealImgUpload
            image={formData?.image}
            errorMessage={errorMessage.image}
            onImageChange={(file) => onTextInputChange('image', file)}
          />
        </div>
      </div>
      <div className="row no-gutters">
        <div className="mt-2 col-12">
          <div className="d-flex justify-content-end">
            <SupportLink title="CONTACT" link="#" className="mr-4" Icon={FaPhone} />
            <SupportLink title="SUPPORT" link="#" className="mr-4" Icon={FaHeadset} />
          </div>
        </div>
      </div>
    </div>
  );
}

MyDealsForm.propTypes = {
  companies: PropTypes.array,
  errorMessage: PropTypes.object,
  formData: PropTypes.object,
  getCompanyList: PropTypes.func,
  getLocationList: PropTypes.func,
  isGettingData: PropTypes.bool,
  isLoadingCompany: PropTypes.bool,
  isLoadingLocation: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  locations: PropTypes.bool,
  onClear: PropTypes.func,
  onSubmit: PropTypes.func,
  pageTitle: PropTypes.string,
  setErrorMessage: PropTypes.func,
  setFormData: PropTypes.func,
};

export default MyDealsForm;
