import React from 'react';
import PropTypes from 'prop-types';
import { FaHeadset, FaPhone, FaPenNib, FaLandmark, FaIcons } from 'react-icons/fa';

import Loader from 'components/Loader';
import Button from 'components/Button/Button';
import CategoryPreview from './CategoryPreview';
import TextInput from 'components/TextInput/TextInput';
import PageTitle from 'components/PageTitle/PageTitle';
import SupportLink from 'components/SupportLink/SupportLink';
import SelectInput from 'components/SelectInput/SelectInput';
import ColorPicker from 'components/ColorPicker/ColorPicker';

import { isFormValid } from 'utils/formValidation';
import { get, isEmpty as isObjectEmpty } from 'utils/object';

import glyphmapIndex from 'assets/generated/glyphmapIndex.json';

function CategoryForm(props) {
  const {
    pageTitle = '',
    formData = {},
    setFormData,
    errorMessage = {},
    setErrorMessage,
    onClear,
    onSubmit,
    isSubmitting,
  } = props;

  const iconLibraryOptions = Object.keys(glyphmapIndex).map((key) => ({ label: key, value: key }));
  const iconOptions = Object.entries(get(glyphmapIndex, formData.iconLibrary, {})).map(([name, code]) => ({
    value: name,
    label: code,
    fontFamily: formData.iconLibrary,
  }));

  const selectedIconLibraryOption = iconLibraryOptions.find(({ value }) => value === formData.iconLibrary);
  const selectedIcon = iconOptions.find(({ value }) => value === formData.icon) || null;

  const handleOnSubmit = () => {
    const requiredFields = {
      backgroundColor: 'Please select background color',
      color: 'Please select icon color',
      icon: 'Please select icon',
      iconLibrary: 'Please select icon library',
      title: 'Please enter title',
    };

    const { isValid, validationMessage } = isFormValid(formData, requiredFields);

    if (!isValid) {
      return setErrorMessage(validationMessage);
    }

    onSubmit();
  };

  const handleIconLibraryChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
      icon: '',
    });
  };

  const onTextInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrorMessage({});
  };

  if (isSubmitting) {
    return <Loader />;
  }

  return (
    <div className="w-100">
      <div className="d-flex justify-content-between mb-4">
        <PageTitle title={pageTitle} />
      </div>

      <div className="row mb-4">
        <div className="col-6">
          <div className="row">
            <div className="col-6">
              <TextInput
                className="mb-4"
                label="Title:"
                placeholder="Lunchtime deals"
                Icon={FaPenNib}
                name="title"
                value={formData.title}
                onChange={onTextInputChange}
                errorMessage={errorMessage.title}
              />
            </div>

            <div className="col-6">
              <TextInput
                className="mb-4"
                label="Sub Title:"
                placeholder=""
                Icon={FaPenNib}
                name="subTitle"
                value={formData.subTitle}
                onChange={onTextInputChange}
                errorMessage={errorMessage.subTitle}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <SelectInput
                className="mb-4"
                label="Icon Library"
                value={selectedIconLibraryOption}
                placeholder="MaterialCommunityIcons"
                isDisabled // TODO: Remove once multiple icon libraries is handled by the app
                Icon={FaLandmark}
                name="iconLibrary"
                options={iconLibraryOptions}
                optionLabel="name"
                onChange={(name, { value }) => handleIconLibraryChange(name, value)}
                errorMessage={errorMessage.areaId}
              />
            </div>

            <div className="col-6">
              <SelectInput
                className="mb-4"
                label="Icon:"
                placeholder="ab-testing"
                value={selectedIcon}
                Icon={FaIcons}
                name="icon"
                options={iconOptions}
                optionLabel="name"
                disabled={!formData.iconLibrary}
                formatOptionLabel={OptionRenderer}
                onChange={(name, { value }) => {
                  onTextInputChange(name, value);
                }}
                errorMessage={errorMessage.areaId}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <ColorPicker
                label="Background Color: "
                value={formData.backgroundColor}
                onChange={(hex) => onTextInputChange('backgroundColor', hex)}
              />
            </div>

            <div className="col-6">
              <ColorPicker label="Color: " value={formData.color} onChange={(hex) => onTextInputChange('color', hex)} />
            </div>
          </div>

          <div className="row">
            <div className="col-6" />

            <div className="col-6">
              <div className="pt-4">
                {onClear && <Button onClick={onClear} title="CLEAR" className="topup-btn pl-5 pr-5 mr-5" />}
                <Button
                  title="SUBMIT"
                  className="submit-btn pl-5 pr-5"
                  disabled={!isObjectEmpty(errorMessage)}
                  onClick={handleOnSubmit}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-6">
          <div className="mb-2">Preview</div>

          <CategoryPreview
            title={formData.title}
            subTitle={formData.subTitle}
            icon={formData.icon}
            iconLibrary={formData.iconLibrary}
            backgroundColor={formData.backgroundColor}
            color={formData.color}
          />
        </div>
      </div>
      <div className="row no-gutters">
        <div className="mt-2 col-12">
          <div className="d-flex justify-content-end">
            <SupportLink title="CONTACT" link="#" className="mr-4" Icon={FaPhone} />
            <SupportLink title="SUPPORT" link="#" className="mr-4" Icon={FaHeadset} />
          </div>
        </div>
      </div>
    </div>
  );
}

CategoryForm.propTypes = {
  pageTitle: PropTypes.string,
  errorMessage: PropTypes.object,
  formData: PropTypes.object,
  isSubmitting: PropTypes.any,
  onClear: PropTypes.any,
  onSubmit: PropTypes.func,
  setErrorMessage: PropTypes.func,
  setFormData: PropTypes.func,
};

export default CategoryForm;

const OptionRenderer = ({ value, label, fontFamily }) => (
  <div style={{ fontFamily }}>
    {!isNaN(label) && String.fromCodePoint(label)} {value}
  </div>
);

OptionRenderer.propTypes = {
  value: PropTypes.string,
  label: PropTypes.object,
  fontFamily: PropTypes.object,
};
