import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import {
  FaPhone,
  FaClock,
  FaHeadset,
  FaEnvelope,
  FaFacebook,
  FaInstagram,
  FaLayerGroup,
  FaMapMarkerAlt,
  FaLocationArrow,
  FaFirefoxBrowser,
} from 'react-icons/fa';
import { CgOrganisation } from 'react-icons/cg';

import PlaceSearch from '../PlaceSearch';
import Button from '../../../../components/Button/Button';
import Loader from '../../../../components/Loader/Loader';
import TextInput from '../../../../components/TextInput/TextInput';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import SupportLink from '../../../../components/SupportLink/SupportLink';
import SelectInput from '../../../../components/SelectInput/SelectInput';

import regex from 'constants/regex';
import { convert12hrTo24hr } from '../../../../utils/date';
import { isEmpty as isStringEmpty } from '../../../../utils/string';
import { isEmpty as isObjectEmpty } from '../../../../utils/object';

const isValidPhoneNumber = (phone) => {
  if (!regex.phoneNumber.test(phone)) return false;
  return true;
};

const isValidTimeSlotFormat = (businessHours) => {
  if (!regex.time.test(businessHours)) return false;

  const [openingTime, closingTime] = businessHours.trim().split('-');

  const openingTime24 = convert12hrTo24hr(openingTime);
  const closingTime24 = convert12hrTo24hr(closingTime);

  return openingTime24 < closingTime24;
};

function LocationForm(props) {
  const {
    pageTitle,
    formData = {},
    setFormData,
    errorMessage = {},
    setErrorMessage,
    onClear,
    onSubmit,
    isSubmitting,
    isGettingData,
    isLoadingArea,
    isLoadingCompany,
    isLoadingCategory,
    areas,
    companies,
    categories,
    getAreaList,
    getCompanyList,
    getCategoryList,
  } = props;

  useEffect(() => {
    getAreaList();
    getCompanyList();
    getCategoryList();
  }, [getAreaList, getCompanyList, getCategoryList]);

  const handleOnSubmit = () => {
    if (!isFormValidated()) {
      return;
    }

    onSubmit();
  };

  const isFormValidated = () => {
    const requiredFields = {
      name: 'Name',
      areaId: 'Area',
      companyId: 'Company',
      businessHours: 'Business Hours',
      categoryId: 'Category',
      phone: 'Phone Number',
      email: 'Email',
    };

    let isValidated = true;
    let validatedMessage = {};

    Object.keys(requiredFields).forEach((fieldName) => {
      if (isStringEmpty(formData[fieldName])) {
        isValidated = false;

        validatedMessage = {
          ...validatedMessage,
          [fieldName]: `${requiredFields[fieldName]} is required`,
        };
      }
    });

    if (!isValidated) {
      setErrorMessage({
        ...errorMessage,
        ...validatedMessage,
      });
      return false;
    }

    return true;
  };

  const onTextInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === 'phone' && !isValidPhoneNumber(value)) {
      setErrorMessage({
        ...errorMessage,
        [name]: 'Invalid Phone Number',
      });

      return;
    }

    if (name === 'businessHours' && !isValidTimeSlotFormat(value)) {
      setErrorMessage({
        ...errorMessage,
        [name]: 'Business Hour should be in 9am - 4pm format',
      });

      return;
    }

    setErrorMessage({});
  };

  const onPlaceSearchChange = (value) => {
    setFormData({
      ...formData,
      name: value.name || '',
      placeId: value.placeId || '',
      phone: value.phone || '',
      website: value.website || '',
      geolocation: value.geolocation || null,
      googleMapsUrl: value.googleMapsUrl,
      businessHours: value.businessHours || '',
      postalCode: value.postalCode || '',
      addressLine1: value.address || '',
    });
  };

  const placeSearchValue = {
    name: formData.name || '',
    googleMapsUrl: formData.googleMapsUrl,
  };

  if (isLoadingArea || isLoadingCompany || isLoadingCategory || isGettingData || isSubmitting) {
    return <Loader />;
  }

  return (
    <div className="w-100">
      <div className="d-flex justify-content-between mb-4">
        <PageTitle title={pageTitle} />
      </div>

      <div className="row no-gutters mb-4">
        <div className="col-4">
          <PlaceSearch
            className="mt-4"
            onChange={onPlaceSearchChange}
            value={placeSearchValue}
            name="name"
            title="Business Location Name"
            subtitle="Type in your business name so that we can find you on Google."
          />
        </div>

        <div className="col-8 pl-4">
          <div className="row no-gutters">
            <SelectInput
              className="col-6 mb-4"
              label="Company:"
              placeholder="Select the Company"
              Icon={CgOrganisation}
              name="companyId"
              options={companies}
              optionKey="_id"
              optionLabel="name"
              value={formData.companyId}
              onChange={onTextInputChange}
              errorMessage={errorMessage.companyId}
            />
          </div>

          <div className="row no-gutters">
            <div className="col-6">
              <TextInput
                className="mb-4"
                label="Business Hours:"
                placeholder="10am - 7pm"
                name="businessHours"
                Icon={FaClock}
                value={formData.businessHours}
                onChange={onTextInputChange}
                errorMessage={errorMessage.businessHours}
              />
              <TextInput
                className="mb-4"
                label="Phone Number:"
                placeholder="+44 20 7234 3456"
                Icon={FaPhone}
                name="phone"
                value={formData.phone}
                onChange={onTextInputChange}
                errorMessage={errorMessage.phone}
              />
              <TextInput
                className="mb-4"
                label="Facebook Profile:"
                placeholder="https://www.facebook.com/mybusiness"
                Icon={FaFacebook}
                name="facebook"
                value={formData.facebook}
                onChange={onTextInputChange}
                errorMessage={errorMessage.facebook}
              />
              <TextInput
                className="mb-4"
                label="Instagram handle:"
                placeholder="my_business"
                Icon={FaInstagram}
                name="instagram"
                value={formData.instagram}
                onChange={onTextInputChange}
                errorMessage={errorMessage.instagram}
              />
              <TextInput
                className="mb-4"
                label="Address:"
                placeholder="London"
                Icon={FaMapMarkerAlt}
                name="addressLine1"
                value={formData.addressLine1}
                onChange={onTextInputChange}
                errorMessage={errorMessage.addressLine1}
              />
            </div>

            <div className="col-6 pl-4">
              <SelectInput
                className="mb-4"
                label="Area:"
                placeholder="Select an Area"
                Icon={FaLocationArrow}
                name="areaId"
                options={areas}
                optionKey="_id"
                optionLabel="name"
                value={formData.areaId}
                onChange={onTextInputChange}
                errorMessage={errorMessage.areaId}
              />
              <SelectInput
                className="mb-4"
                label="Category:"
                placeholder="Pubs, Clubs and Bar"
                Icon={FaLayerGroup}
                name="categoryId"
                options={categories}
                optionKey="_id"
                optionLabel="name"
                value={formData.categoryId}
                onChange={onTextInputChange}
                errorMessage={errorMessage.categoryId}
              />
              <TextInput
                className="mb-4"
                label="Email:"
                placeholder="admin@newbusiness.com"
                name="email"
                Icon={FaEnvelope}
                value={formData.email}
                onChange={onTextInputChange}
                errorMessage={errorMessage.email}
              />
              <TextInput
                className="mb-4"
                label="Website:"
                placeholder="www.mynewbusiness.com"
                Icon={FaFirefoxBrowser}
                name="website"
                value={formData.website}
                onChange={onTextInputChange}
                errorMessage={errorMessage.website}
              />
              <TextInput
                className="mb-4"
                label="Postal Code:"
                placeholder="44300"
                Icon={FaEnvelope}
                name="postalCode"
                type="number"
                value={formData.postalCode}
                onChange={onTextInputChange}
                errorMessage={errorMessage.postalCode}
              />
              <div className="d-flex justify-content-end mt-5">
                <Button onClick={onClear} title="CLEAR" className="topup-btn mr-4 pl-5 pr-5" />
                <Button
                  title="SUBMIT"
                  className="submit-btn pl-5 pr-5"
                  disabled={!isObjectEmpty(errorMessage)}
                  onClick={handleOnSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row no-gutters">
        <div className="mt-5 col-12">
          <div className="d-flex justify-content-end">
            <SupportLink title="CONTACT" link="#" className="mr-4" Icon={FaPhone} />
            <SupportLink title="SUPPORT" link="#" className="mr-4" Icon={FaHeadset} />
          </div>
        </div>
      </div>
    </div>
  );
}

LocationForm.propTypes = {
  areas: PropTypes.array,
  categories: PropTypes.array,
  companies: PropTypes.array,
  errorMessage: PropTypes.object,
  formData: PropTypes.object,
  getAreaList: PropTypes.func,
  getCategoryList: PropTypes.func,
  getCompanyList: PropTypes.func,
  isGettingData: PropTypes.bool,
  isLoadingArea: PropTypes.bool,
  isLoadingCategory: PropTypes.bool,
  isLoadingCompany: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onClear: PropTypes.func,
  onSubmit: PropTypes.func,
  pageTitle: PropTypes.any,
  setErrorMessage: PropTypes.func,
  setFormData: PropTypes.func,
};

export default LocationForm;
