import http from '../utils/http';

import * as endpoints from '../constants/endpoints';

export const getUsers = async (filters = {}) => {
  const { data } = await http.get(endpoints.USER, {
    params: filters,
  });

  return data;
};
