import Select from 'react-select';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, { useState } from 'react';

import useFetchOptions from './hooks/useFetchOptions';
import useDebounceValue from '../../hooks/useDebounceValue';
import InputLabel from 'components/InputLabel/InputLabel';

const Search = (props) => {
  const {
    label,
    placeholder = 'Search',
    onChange = () => {},
    className,
    labelClassName = '',
    fetchOptionsFunction,
    isClearable = false,
  } = props;

  const [searchText, setSearchText] = useState('');

  const debouncedSearchText = useDebounceValue(searchText);

  const { isLoading, options } = useFetchOptions({
    fetchFunction: fetchOptionsFunction,
    searchText: debouncedSearchText,
  });

  return (
    <div
      className={classnames('text-input', {
        [className]: className,
      })}
    >
      {label && <InputLabel label={label} className={labelClassName} />}
      <div
        className={classnames('text-input__input', {
          'text-input__input--no-icon': true,
        })}
      >
        <Select
          options={options}
          onInputChange={setSearchText}
          isLoading={isLoading}
          loadingMessage={() => 'Searching'}
          noOptionsMessage={() => ''}
          styles={{
            control: (provided) => ({
              ...provided,
              fontSize: 12,
              borderWidth: 0,
              backgroundColor: 'none',
            }),
          }}
          isClearable={isClearable}
          onChange={onChange}
          components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

Search.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  /** fetchOptionsFunction: function should return array of react select options */
  fetchOptionsFunction: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  isClearable: PropTypes.bool,
};

export default Search;
