import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const SupportLink = (props) => {
  const { Icon, title, className, link } = props;

  return (
    <a
      href={link}
      className={classnames('support-link', {
        [className]: className,
      })}
    >
      {Icon && <Icon className="support-link__icon" />}
      {title}
    </a>
  );
};

SupportLink.propTypes = {
  Icon: PropTypes.any,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default SupportLink;
