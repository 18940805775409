import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Toast from 'components/Toast';
import Loader from 'components/Loader';
import CategoryForm from 'screens/Categories/CategoryForm/CategoryForm';

import { withAttrs } from 'utils/object';
import { handleError } from 'utils/errorHandler';
import { getCategoryById, updateCategory } from 'service/category';

const initialFormData = {
  backgroundColor: '',
  color: '',
  icon: '',
  iconLibrary: '',
  subTitle: '',
  title: '',
};

const EditCategory = (props) => {
  const id = props?.match?.params?.id;

  const [errorMessage, setErrorMessage] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [isGettingData, setIsGettingData] = useState(false);

  useEffect(() => {
    const fetchCategory = async () => {
      setIsGettingData(true);

      try {
        const category = await getCategoryById(id);

        setFormData(withAttrs(category, Object.keys(initialFormData)));
      } catch (error) {
        handleError(error);
      }

      setIsGettingData(false);
    };

    fetchCategory();
  }, [id]);

  const onClear = () => {
    setFormData(initialFormData);
    setErrorMessage({});
  };

  const onSubmit = async () => {
    setIsSubmitting(true);

    try {
      await updateCategory(id, formData);
      Toast.success({ title: 'Success', message: 'Category edited successfully!' });
    } catch (err) {
      handleError(err);
    }

    setIsSubmitting(false);
  };

  if (isGettingData) {
    return <Loader />;
  }

  return (
    <CategoryForm
      pageTitle="Add new Deals"
      isSubmitting={isSubmitting}
      formData={formData}
      errorMessage={errorMessage}
      onClear={onClear}
      onSubmit={onSubmit}
      setFormData={setFormData}
      setErrorMessage={setErrorMessage}
    />
  );
};

EditCategory.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any,
    }),
  }),
};

export default EditCategory;
