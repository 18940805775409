import { get as objectGet } from './object';
import { isEmpty as isArrayEmpty } from './array';
import { isEmpty as isStringEmpty } from './string';

/**
 * Checks if the form is valid.
 *
 * @param {Object} formValues
 * @param {Object} requiredFields
 *
 * @returns {Object} obj
 * @returns {Boolean} obj.isValid
 * @returns {Object} obj.validationMessage
 */
export const isFormValid = (formValues, requiredFields) => {
  let isValid = true;
  let validationMessage = {};

  Object.keys(requiredFields).forEach((fieldName) => {
    const value = objectGet(formValues, fieldName);
    if ((Array.isArray(value) && isArrayEmpty(value)) || isStringEmpty(value)) {
      isValid = false;

      validationMessage = {
        ...validationMessage,
        [fieldName]: `${requiredFields[fieldName]} is required`,
      };
    }
  });

  return { isValid, validationMessage };
};
