import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Button = (props) => {
  const { title, onClick, className, disabled } = props;
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={classnames('btn', {
        [className]: className,
      })}
    >
      {title}
    </button>
  );
};

Button.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default Button;
