import { PropTypes } from 'prop-types';
import React, { useEffect } from 'react';
import { useTable, useSortBy, useGlobalFilter, usePagination, useExpanded } from 'react-table';

/**
 * Common Table Component.
 *
 * @param {Object} Props
 *
 * @returns {Component}
 */
function Table(props) {
  const {
    columns,
    data,
    isLoading = false,
    noDataMessage = 'No data found',
    showNoDataMessage = true,
    sortBy = [],
    filterText,
  } = props;

  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow, setGlobalFilter, footerGroups } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      initialState: {
        sortBy: sortBy,
        hiddenColumns: [],
      },
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  );

  useEffect(() => {
    setGlobalFilter(filterText);
  }, [setGlobalFilter, filterText]);

  return (
    <div>
      <table {...getTableProps()} className="hourly-table">
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column, index) => (
                <th {...column.getHeaderProps()} key={index} width={column?.customWidth} className="table-head-column">
                  <div className="table-head-column-content">{column.render('Header')}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);

            return (
              <tr {...row.getRowProps()} className={`table-body-row ${i % 2 ? 'light' : 'dark'}`} key={i}>
                {row.cells.map((cell, index) => {
                  return (
                    <td key={index} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}

          {!rows.length && !isLoading && showNoDataMessage && (
            <tr>
              <td colSpan={1000} style={{ paddingTop: 20, paddingBottom: 20 }}>
                <span className="d-flex justify-content-center">{noDataMessage}</span>
              </td>
            </tr>
          )}
        </tbody>

        <tfoot>
          {footerGroups.map((group, i) => {
            const isFooterAvailable =
              group.headers.filter((column) => column.Footer && typeof column.Footer !== 'function').length > 0;

            return (
              isFooterAvailable && (
                <tr {...group.getFooterGroupProps()} key={i}>
                  {group.headers.map((column, index) => {
                    return (
                      <td {...column.getFooterProps()} key={index}>
                        {column.render('Footer')}
                      </td>
                    );
                  })}
                </tr>
              )
            );
          })}
        </tfoot>
      </table>
    </div>
  );
}

Table.propTypes = {
  columns: PropTypes.any,
  data: PropTypes.any,
  filterText: PropTypes.any,
  isLoading: PropTypes.bool,
  noDataMessage: PropTypes.string,
  showNoDataMessage: PropTypes.bool,
  sortBy: PropTypes.array,
};

export default Table;
