import http from '../utils/http';
import * as endpoints from '../constants/endpoints';

export const fetchCustomerOptions = async (filters) => {
  const { data } = await http.get(endpoints.CUSTOMER_OPTIONS, {
    params: filters,
  });

  return data.map(({ _id: value, fullName: label }) => ({ label, value }));
};

export const fetchLocationOptions = async (filters = {}) => {
  const { data } = await http.get(endpoints.LOCATION_OPTIONS, {
    params: filters,
  });

  return data.map(({ _id: value, name: label }) => ({ label, value }));
};

export const fetchCompanyOptions = async (filters) => {
  const { data } = await http.get(endpoints.COMPANY_OPTIONS, {
    params: filters,
  });

  return data.map(({ _id: value, name: label }) => ({ label, value }));
};

export const fetchUserOptions = async (filters) => {
  const { data } = await http.get(endpoints.USER_OPTIONS, {
    params: filters,
  });

  return data.map(({ _id: value, fullName: label }) => ({ label, value }));
};

export const fetchAreaOptions = async (filters) => {
  const { data } = await http.get(endpoints.AREA_OPTIONS, {
    params: filters,
  });

  return data.map(({ _id: value, name: label }) => ({ label, value }));
};

export const fetchDealOptions = async (filters) => {
  const { data } = await http.get(endpoints.DEAL_OPTIONS, {
    params: filters,
  });

  return data.map(({ _id: value, title: label }) => ({ label, value }));
};
