import React from 'react';
import PropTypes from 'prop-types';

import Sidenav from './components/Sidenav/SideNav';

const WithSideNav = ({ children }) => {
  return (
    <>
      <Sidenav />
      <div className="app-content__main">{children}</div>
    </>
  );
};

WithSideNav.propTypes = {
  children: PropTypes.any,
};

export default WithSideNav;
