import { GET_CUSTOMER_LIST, GET_CUSTOMER_LIST_FAIL, GET_CUSTOMER_LIST_SUCCESS } from '../types';

import * as customerService from '../../service/customer';

export const getCustomerList = (filters = {}) => {
  return function (dispatch) {
    dispatch({ type: GET_CUSTOMER_LIST });

    const onGetCustomerListSuccess = (response) =>
      dispatch({
        type: GET_CUSTOMER_LIST_SUCCESS,
        payload: response,
      });

    const onGetCustomerListError = (error) => dispatch({ type: GET_CUSTOMER_LIST_FAIL, payload: error });

    return customerService.getCustomers(filters).then(onGetCustomerListSuccess).catch(onGetCustomerListError);
  };
};
