import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import ROUTES from './constants/routes';

import Deals from './screens/Deals';
import Users from './screens/Users';
import AddDeal from './screens/AddDeal';
import Login from './screens/Auth/Login';
import EditDeal from './screens/EditDeal';
import NotFound from './screens/NotFound';
import Customers from './screens/Customers';
import Locations from './screens/Locations';
import EditProfile from './screens/EditCustomer';
import AddLocation from './screens/AddLocation';
import EditLocation from './screens/EditLocation';

// import Billing from './screens/Billing';
// import PaymentFailure from 'screens/PaymentFailure/PaymentFailure';
// import PaymentSuccess from './screens/PaymentSuccess/PaymentSuccess';

import Header from './components/Header';
import Footer from './components/Footer/Footer';

import WithSideNav from './WithSideNav';
import EditCustomer from 'screens/EditCustomer';
import CategoriesContainer from 'screens/Categories';
import AuthenticatedRoutes from './AuthenticatedRoutes';
import AddCategoryContainer from 'screens/AddCategory/AddCategory.container';
import EditCategoryContainer from 'screens/EditCategory/EditCategory.container';

const Router = () => {
  return (
    <div className="position-relative h-100">
      <Header />
      <Switch>
        <Route exact path={ROUTES.AUTH.LOGIN} component={Login} />
        <Route exact path={ROUTES.NOT_FOUND} component={NotFound} />

        <AuthenticatedRoutes>
          <div className="app-content-container">
            <WithSideNav>
              <div className="app-content">
                <Route exact path={ROUTES.HOME} component={AddDeal} />
                <Route exact path={ROUTES.USER.EDIT_PROFILE} component={EditProfile} />

                <Route exact path={ROUTES.USERS} component={Users} />
                <Route exact path={ROUTES.CUSTOMERS} component={Customers} />
                <Route exact path={ROUTES.EDIT_CUSTOMER} component={EditCustomer} />

                <Route exact path={ROUTES.DEALS} component={Deals} />
                <Route exact path={ROUTES.CREATE_DEAL} component={AddDeal} />
                <Route exact path={ROUTES.EDIT_DEAL} component={EditDeal} />

                <Route exact path={ROUTES.LOCATION} component={Locations} />
                <Route exact path={ROUTES.ADD_LOCATION} component={AddLocation} />
                <Route exact path={ROUTES.EDIT_LOCATION} component={EditLocation} />

                <Route exact path={ROUTES.CATEGORIES} component={CategoriesContainer} />
                <Route exact path={ROUTES.CREATE_CATEGORY} component={AddCategoryContainer} />
                <Route exact path={ROUTES.EDIT_CATEGORY} component={EditCategoryContainer} />

                {/* Removed for Release */}
                {/* <Route exact path={ROUTES.SPENDING} component={Billing} />
                <Route exact path={ROUTES.PAYMENT_SUCCESS} component={PaymentSuccess} />
                <Route exact path={ROUTES.PAYMENT_FAIL} component={PaymentFailure} /> */}
              </div>
            </WithSideNav>
          </div>
        </AuthenticatedRoutes>

        <Redirect to={ROUTES.NOT_FOUND} />
      </Switch>
      <Footer />
    </div>
  );
};

export default Router;
