import thunk from 'redux-thunk';
import { createStore, compose, applyMiddleware } from 'redux';

import config from 'config';
import { rootReducer } from './reducers';

// create a redux store with our reducer above and middleware
// only show redux store in development
const composeEnhancers =
  config.devMode && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(applyMiddleware(thunk));

const store = createStore(rootReducer, enhancer);

export default store;
