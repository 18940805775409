import http from '../../utils/http';
import * as endpoints from '../../constants/endpoints';
import { GET_DEAL_LIST, GET_DEAL_LIST_FAIL, GET_DEAL_LIST_SUCCESS } from '../types';

export const getDealList = (filters = {}) => {
  return function (dispatch) {
    dispatch({ type: GET_DEAL_LIST });

    return http
      .get(endpoints.DEAL, {
        params: filters,
      })
      .then((res) => dispatch(saveDeals(res.data)))
      .catch((err) => dispatch(errorOnFetchDeals(err)));
  };
};

const saveDeals = (payload) => ({
  type: GET_DEAL_LIST_SUCCESS,
  payload,
});

const errorOnFetchDeals = (payload) => ({
  type: GET_DEAL_LIST_FAIL,
  payload,
});
