import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import classnames from 'classnames';

import InputLabel from 'components/InputLabel/InputLabel';

const SelectInput = (props) => {
  const {
    label,
    placeholder = '',
    onChange = () => {},
    value,
    Icon,
    className,
    labelClassName = '',
    errorMessage,
    name,
    isMulti = false,
    options = [],
    hideDropdownIndicator = false,
    isClearable = false,
    formatOptionLabel,
    isDisabled = false,
  } = props;

  const extraReactSelectProps = {};

  if (hideDropdownIndicator) {
    extraReactSelectProps.components = { DropdownIndicator: () => null, IndicatorSeparator: () => null };
  }

  let selectedValue;

  if (isMulti) {
    selectedValue = options?.filter((option) => value?.find(({ value }) => value === option?.value)) || [{}];
  } else {
    selectedValue = options?.find((option) => option?.value === value?.value) || {};
  }

  return (
    <div
      className={classnames('text-input', {
        [className]: className,
      })}
    >
      {label && <InputLabel className={labelClassName} label={label} />}
      <div
        className={classnames('text-input__input', {
          'text-input__input--no-icon': !Icon,
        })}
      >
        {Icon && <Icon className="text-input__input__icon" />}
        <Select
          value={selectedValue}
          options={options}
          isMulti={isMulti}
          formatOptionLabel={formatOptionLabel}
          styles={{
            control: (provided) => ({
              ...provided,
              fontSize: 12,
              borderWidth: 0,
              paddingLeft: 24,
              backgroundColor: 'none',
            }),
          }}
          onChange={(value) => onChange(name, value)}
          placeholder={placeholder}
          isClearable={isClearable}
          isDisabled={isDisabled}
          {...extraReactSelectProps}
        />
      </div>
      {errorMessage && <div className="text-input__info">{errorMessage}</div>}
    </div>
  );
};

SelectInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  label: PropTypes.string,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.array,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  Icon: PropTypes.elementType,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  formatOptionLabel: PropTypes.func,
  hideDropdownIndicator: PropTypes.bool,
  isClearable: PropTypes.bool,
};

export default SelectInput;
