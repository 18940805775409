import http from '../../utils/http';
import * as endpoints from '../../constants/endpoints';
import { GET_COMPANY_LIST, GET_COMPANY_LIST_SUCCESS, GET_COMPANY_LIST_FAIL } from '../types';

const getCompanyListSuccess = (payload) => ({
  type: GET_COMPANY_LIST_SUCCESS,
  payload,
});

const getCompanyListFail = (payload) => ({
  type: GET_COMPANY_LIST_FAIL,
  payload,
});

export const getCompanyList = () => {
  return function (dispatch) {
    dispatch({ type: GET_COMPANY_LIST });

    return http
      .get(endpoints.COMPANY)
      .then((res) => dispatch(getCompanyListSuccess(res.data)))
      .catch((err) => dispatch(getCompanyListFail(err)));
  };
};
