import { convert12hrTo24hr, convert24HourTo12hr } from 'utils/date';

export const formatLocationAPIDataForForm = (locationData) => {
  const {
    name,
    googleMapsUrl,
    businessHours,
    contacts,
    areaId,
    companyId,
    categoryId,
    social,
    addressLine1,
    postalCode,
    ...rest
  } = locationData;
  const { startsAt, endsAt } = businessHours;
  const { phone, email } = contacts;
  const { facebook, instagram, website } = social;

  const formattedData = {
    ...rest,
    name,
    googleMapsUrl,
    companyId: {
      label: '',
      value: companyId,
    },
    areaId: {
      label: '',
      value: areaId,
    },
    categoryId: {
      label: '',
      value: categoryId,
    },
    phone,
    email,
    facebook,
    instagram,
    website,
    addressLine1,
    postalCode,
    businessHours: convert24HourTo12hr(startsAt, endsAt),
  };

  return formattedData;
};

export const formatLocationDataForAPI = (location) => {
  const [openingTime, closingTime] = location.businessHours.split('-');
  const {
    facebook,
    name,
    areaId,
    companyId,
    categoryId,
    instagram,
    website,
    phone,
    email,
    placeId,
    geolocation,
    addressLine1,
    postalCode,
    googleMapsUrl,
  } = location;
  const openingTime24 = convert12hrTo24hr(openingTime);
  const closingTime24 = convert12hrTo24hr(closingTime);

  if (closingTime24 < openingTime24) throw new Error('Opening Time is less than Closing Time!');

  const formattedlocation = {
    name,
    areaId: areaId.value,
    companyId: companyId.value,
    categoryId: categoryId.value,
    businessHours: {
      startsAt: openingTime24,
      endsAt: closingTime24,
    },
    social: {
      facebook,
      instagram,
      website,
    },
    contacts: {
      phone,
      email,
    },
    placeId,
    geolocation,
    postalCode: Number(postalCode),
    addressLine1,
    googleMapsUrl,
  };

  return formattedlocation;
};
