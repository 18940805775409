import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import ROUTES from '../../../constants/routes';
import { ADMIN_LOGIN_SUCCESS } from 'redux/types';

import { handleError } from '../../../utils/errorHandler';
import { isFormValid } from '../../../utils/formValidation';
import { isEmpty as isObjectEmpty } from '../../../utils/object';

import Button from '../../../components/Button/Button';
import TextInput from '../../../components/TextInput/TextInput';

import WelcomeVector from '../../../assets/images/welcome.svg';

const Login = (props) => {
  const initialFormData = {
    email: '',
    password: '',
  };

  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState({});
  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    if (isObjectEmpty(props.loginError)) return;

    handleError(props.loginError);
  }, [props.loginError]);

  const onTextInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrorMessage({});
  };

  const loginAdmin = async ({ email, password }) => {
    const response = await props.login(email, password);

    if (response.type === ADMIN_LOGIN_SUCCESS) {
      history.replace(ROUTES.HOME);
    }
  };

  const handleLoginClick = async () => {
    const requiredFields = {
      email: 'Email',
      password: 'Password',
    };

    const { isValid, validationMessage } = isFormValid(formData, requiredFields);

    if (!isValid) {
      return setErrorMessage(validationMessage);
    }

    loginAdmin(formData);
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <div className="login-card__welcome-card">
          <img src={WelcomeVector} alt="welcome image" />
          <div className="login-card-text">
            <div className="login-card-text__title">WELCOME TO HOURLY</div>
            <div className="login-card-text__sub-title">A great deal, every hour</div>
          </div>
        </div>

        <div className="login-card__vertical-border" />

        <div className="login-card__form login-form-container">
          <div className="login-form-container__title mb-2">ADMIN LOGIN</div>
          <div className="login-form-container__sub-title mb-3">
            Build offers with our team to drive customers to you, when you need them.
          </div>

          <div>
            <TextInput
              className="mb-3"
              placeholder="Email"
              name="email"
              onChange={onTextInputChange}
              errorMessage={errorMessage.email}
            />
            <TextInput
              className="mb-3"
              type="password"
              placeholder="Password"
              name="password"
              onChange={onTextInputChange}
              errorMessage={errorMessage.password}
            />
            <div className="d-flex justify-content-end mt-4">
              <Button onClick={handleLoginClick} title="LOGIN" className="button-login" disabled={props.isSubmitting} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {
  loginError: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
};

export default Login;
