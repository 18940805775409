import { capitalize } from 'utils/string';
import { convert12hrTo24hr, convert24HourTo12hr } from 'utils/date';

export const formatDealAPIDataForForm = (dealData) => {
  const { title, days, companyId, locationIds, timeSlot, discount, claimCodes, imageUrl } = dealData;
  const { startsAt, endsAt } = timeSlot;

  const formattedDate =
    days?.map((day) => {
      const capitalizedDay = capitalize(day);

      return {
        label: capitalizedDay,
        value: capitalizedDay,
      };
    }) || [];

  const formattedLocation = locationIds?.map((locationid) => ({
    label: '',
    value: locationid,
  }));

  const image = [];

  if (imageUrl) {
    const imageData = {
      data_url: imageUrl,
      file: null,
    };

    image.push(imageData);
  }

  const formattedDeal = {
    title,
    companyId: {
      label: '',
      value: companyId,
    },
    days: formattedDate,
    locationIds: formattedLocation,
    timeSlot: convert24HourTo12hr(startsAt, endsAt),
    discount,
    claimCodes,
    image,
  };

  return formattedDeal;
};

export const formatDealDataForAPI = (data) => {
  const { image, timeSlot, days, companyId, locationIds } = data;

  const [openingTime, closingTime] = timeSlot.split('-');
  const openingTime24 = convert12hrTo24hr(openingTime);
  const closingTime24 = convert12hrTo24hr(closingTime);

  if (closingTime24 < openingTime24) {
    throw new Error('Opening Time is less than Closing Time!');
  }

  const file = image?.[0]?.file;
  const formattedData = {
    ...data,
    days: days.map((item) => item.value),
    companyId: companyId.value,
    locationIds: locationIds.map((item) => item.value),
    timeSlot: {
      startsAt: openingTime24,
      endsAt: closingTime24,
    },
  };

  if (!file) {
    formattedData.imageUrl = image?.[0]?.data_url;
  }

  if (formattedData?.image) {
    delete formattedData.image;
  }

  const form = new FormData();
  const formData = JSON.stringify(formattedData);

  form.append('file', file);
  form.append('formData', formData);

  return form;
};
