/**
 * Build supplied string by interpolating properties after delimiter ':' with the given parameters.
 *
 * @example
 * interpolate(':name is here.', {name: 'Colly'})
 * => 'Colly is here.'
 *
 * @param {string} str
 * @param {object} params
 * @param {object} queries
 *
 * @returns String.
 */
export const interpolate = (str, params, queries = {}) => {
  if (typeof str !== 'string') {
    throw new TypeError('First Argument must be a string');
  }
  let formattedString = str;

  params = params || {}; // default params won't resolve to {} if null is passed.
  for (const [key, value] of Object.entries(params)) {
    const val = value || '';

    formattedString = formattedString.replace(new RegExp(':' + key + '\\b', 'gi'), val.toString());
  }

  if (Object.values(queries).filter((a) => a).length) {
    formattedString += '?';
    Object.entries(queries).forEach(([name, value]) => {
      if (name && value) {
        formattedString += `${name}=${value}&`;
      }
    });
    formattedString = formattedString.slice(0, formattedString.length - 1);
  }

  return formattedString;
};

/**
 * Checks whether the incoming value is empty or null.
 *
 * @param {String} value
 */
export const isEmpty = (value) => {
  if (typeof value === 'string') {
    return !value.trim();
  }
  return !value;
};

/**
 * Capitalizes first letters of words in string.
 * @param {string} str String to be modified
 * @param {boolean=false} lower Whether all other letters should be lowercased
 * @return {string}
 * @example
 *   capitalize('fix this string');     // -> 'Fix This String'
 *   capitalize('javaSCrIPT');          // -> 'Javascript'
 *   capitalize('javaSCrIPT', true);    // -> 'Javascript'
 */
export const capitalize = (str, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());
