import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import Toast from '../../components/Toast';
import Loader from '../../components/Loader/Loader';
import Button from '../../components/Button/Button';
import TextInput from '../../components/TextInput/TextInput';
import PageTitle from '../../components/PageTitle/PageTitle';
import UserImageUpload from './components/UserImageUpload';

import { handleError } from 'utils/errorHandler';
import { isFormValid } from '../../utils/formValidation';

import regex from 'constants/regex';
import { getCustomerById, updateCustomerData } from 'service/customer';

const isValidPhoneNumber = (phone) => {
  if (!regex.phoneNumber.test(phone)) return false;
  return true;
};

const initialFormData = {
  fullName: '',
  businessName: '',
  email: '',
  phone: '',
  image: [],
};

const EditCustomer = (props) => {
  const id = props?.match?.params?.id;

  const [errorMessage, setErrorMessage] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [isGettingData, setIsGettingData] = useState(false);

  useEffect(() => {
    const fetchCustomer = async () => {
      setIsGettingData(true);

      try {
        const customer = await getCustomerById(id);

        const image = [];

        if (customer?.imageUrl) {
          const imageData = {
            data_url: customer?.imageUrl,
            file: null,
          };

          image.push(imageData);
        }

        const formData = {
          fullName: customer?.fullName,
          businessName: customer?.businessName,
          email: customer?.contacts?.email,
          phone: customer?.contacts?.phone,
          image,
        };

        setFormData(formData);
      } catch (error) {
        handleError(error);
      }

      setIsGettingData(false);
    };

    fetchCustomer();
  }, [id]);

  const onTextInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === 'phone' && !isValidPhoneNumber(value)) {
      setErrorMessage({
        ...errorMessage,
        [name]: 'Invalid Phone Number',
      });

      return;
    }

    setErrorMessage({});
  };

  const isFormValidated = () => {
    const requiredFields = {
      fullName: 'Full Name',
      businessName: 'Business Name',
      email: 'Email',
      phone: 'Phone',
    };

    let { isValid, validationMessage } = isFormValid(formData, requiredFields);

    if (!formData?.image?.[0]?.data_url) {
      isValid = false;

      validationMessage = {
        ...validationMessage,
        image: 'Image is required',
      };
    }

    if (!isValid) {
      setErrorMessage(validationMessage);
    }

    return isValid;
  };

  const onSubmit = async () => {
    if (!isFormValidated()) {
      return;
    }

    try {
      setIsSubmitting(true);

      await updateCustomerData(id, formData);

      Toast.success({ title: 'OK', message: 'Profile Updated successfully!' });
      setIsSubmitting(false);
    } catch (err) {
      setIsSubmitting(false);
      handleError(err);
    }
  };

  if (isGettingData) {
    return <Loader />;
  }

  return (
    <main className="w-100">
      <header className="d-flex justify-content-between">
        <PageTitle title="Edit Profile" />
      </header>

      <UserImageUpload
        image={formData?.image}
        errorMessage={errorMessage.image}
        onImageChange={(file) => onTextInputChange('image', file)}
      />
      <section className="mt-5">
        <h3 className="fs-16px fw-700 color-greyish-brown">Account Information</h3>

        <div className="row">
          <TextInput
            className="col-6"
            label="Full Name"
            name="fullName"
            value={formData.fullName}
            onChange={onTextInputChange}
            errorMessage={errorMessage.fullName}
          />

          <TextInput
            className="col-6 mb-4"
            label="Company Name"
            name="businessName"
            value={formData.businessName}
            onChange={onTextInputChange}
            errorMessage={errorMessage.businessName}
          />
        </div>

        <div className="row">
          <TextInput
            className="col-6"
            label="Email"
            name="email"
            disabled
            value={formData.email}
            onChange={onTextInputChange}
            errorMessage={errorMessage.email}
          />

          <TextInput
            className="col-6 mb-4"
            label="Phone Number"
            name="phone"
            value={formData.phone}
            onChange={onTextInputChange}
            errorMessage={errorMessage.phone}
          />
        </div>
      </section>

      <section className="row no-gutters mt-3 pb-5">
        <Button
          title="SAVE CHANGES"
          className="submit-btn pl-5 pr-5 ml-auto"
          disabled={isSubmitting}
          onClick={onSubmit}
        />
      </section>
    </main>
  );
};

EditCustomer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any,
    }),
  }),
};

export default EditCustomer;
