import React from 'react';

import facebookImg from '../../assets/images/facebook.svg';
import linkedInImg from '../../assets/images/linkedin.svg';
import instagramImg from '../../assets/images/instagram.svg';
import hourlyCombinationMarkLogo from '../../assets/images/hourly-logo-combination-mark.png';

const Footer = () => {
  return (
    <div className="footer">
      <img className="hourly-footer-icon" src={hourlyCombinationMarkLogo} alt="hourly combination mark" />

      <div className="social-media-links">
        <a href="#">
          <img src={facebookImg} alt="facebook" />
        </a>
        <a href="#">
          <img src={instagramImg} alt="facebook" />
        </a>
        <a href="#">
          <img src={linkedInImg} alt="facebook" />
        </a>
      </div>

      <div className="copyright-text">
        <span>Copyright © 2021 hourly.co – All Rights Reserved</span>
      </div>
    </div>
  );
};

export default Footer;
