import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FaInfoCircle } from 'react-icons/fa';
import React, { useEffect, useMemo } from 'react';

import Table from 'components/Table';
import Loader from '../../components/Loader/Loader';
import LoginInfo from '../../components/LoginInfo/LoginInfo';
import PageTitle from '../../components/PageTitle/PageTitle';

import ROUTES from '../../constants/routes';

import { interpolate } from '../../utils/string';

const Categories = (props) => {
  const { getCategoryList, categories, isLoading } = props;
  useEffect(() => {
    getCategoryList();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'title',
      },
      {
        Header: 'Sub Title',
        accessor: 'subTitle',
      },
      {
        Header: 'Icon Library',
        accessor: 'iconLibrary',
      },
      {
        id: 'action',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original = {} } }) => {
          const editUrl = interpolate(ROUTES.EDIT_CATEGORY, { id: original?._id || '' });

          return (
            <Link className="text-decoration-none link-button color-windows-blue" to={editUrl}>
              Edit
            </Link>
          );
        },
      },
    ],
    []
  );

  const renderTable = () => {
    if (isLoading) {
      return <Loader />;
    }

    return <Table data={categories} columns={columns} />;
  };

  return (
    <div className="w-100">
      <div className="d-flex justify-content-between mb-4">
        <PageTitle title="Categories" />
        <LoginInfo />
      </div>

      <div className="table-container">{renderTable()}</div>

      <div className="d-flex justify-content-between deals-info">
        <div className="d-flex align-items-center">
          <FaInfoCircle color="#007aff" /> &nbsp; Click to get more information.
        </div>
        <Link className="text-decoration-none" to={ROUTES.CREATE_CATEGORY}>
          <button className="d-flex align-items-center link-button">
            Add a new Category &nbsp;
            <div className="d-flex align-items-center justify-content-center add-deal">+</div>
          </button>
        </Link>
      </div>
    </div>
  );
};

Categories.propTypes = {
  getCategoryList: PropTypes.func,
  categories: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default Categories;
