import React, { useRef, useEffect, cloneElement } from 'react';

const eventTypeMapping = {
  click: 'onClick',
  mousedown: 'onMouseDown',
  mouseup: 'onMouseUp',
  touchstart: 'onTouchStart',
  touchend: 'onTouchEnd',
};

/**
 * Component to Detect when Click has Occurred outside the Component.
 * Taken from: https://github.com/ooade/react-click-away-listener/blob/main/src/index.tsx.
 *
 * @param {Object} Props { children, onClickAway, mouseEvent = 'click', touchEvent = 'touchend' }.
 *
 * @returns {*}
 */
const ClickAwayListener = ({ children, onClickAway, mouseEvent = 'click', touchEvent = 'touchend' }) => {
  const node = useRef(null);
  const mountedRef = useRef(false);
  const bubbledEventTarget = useRef(null);

  const mappedMouseEvent = eventTypeMapping[mouseEvent];
  const mappedTouchEvent = eventTypeMapping[touchEvent];

  /**
   * Prevents the bubbled event from getting triggered immediately.
   * Issue: https://github.com/facebook/react/issues/20074.
   */
  useEffect(() => {
    setTimeout(() => {
      mountedRef.current = true;
    }, 0);

    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    /**
     * Handle Events.
     *
     * @param {*} event
     * @returns {*}
     */
    const handleEvents = (event) => {
      if (!mountedRef.current) return;

      if (
        (node.current && node.current.contains(event.target)) ||
        bubbledEventTarget.current === event.target ||
        !document.contains(event.target)
      ) {
        return;
      }

      onClickAway(event);
    };

    document.addEventListener(mouseEvent, handleEvents);
    document.addEventListener(touchEvent, handleEvents);

    return () => {
      document.removeEventListener(mouseEvent, handleEvents);
      document.removeEventListener(touchEvent, handleEvents);
    };
  }, [mouseEvent, onClickAway, touchEvent]);

  /**
   * Handle the Bubbled Events.
   *
   * @param {*} type
   */
  const handleBubbledEvents = (type) => (event) => {
    bubbledEventTarget.current = event.target;
    // eslint-disable-next-line no-void
    const handler = children === null || children === void 0 ? void 0 : children.props[type];

    if (handler) {
      handler(event);
    }
  };

  /**
   * Change/Fix Child ref on Children Change.
   *
   * @param {*} childRef
   */
  const handleChildRef = (childRef) => {
    node.current = childRef;
    const { ref } = children;

    if (typeof ref === 'function') {
      ref(childRef);
    } else if (ref) {
      ref.current = childRef;
    }
  };

  return React.Children.only(
    cloneElement(children, {
      ref: handleChildRef,
      [mappedMouseEvent]: handleBubbledEvents(mappedMouseEvent),
      [mappedTouchEvent]: handleBubbledEvents(mappedTouchEvent),
    })
  );
};

export default ClickAwayListener;
