import {
  CLEAR_AUTH_STATE,
  ADMIN_LOGIN,
  ADMIN_LOGIN_FAIL,
  ADMIN_LOGIN_SUCCESS,
  GET_ADMIN_INFO,
  GET_ADMIN_INFO_FAIL,
  GET_ADMIN_INFO_SUCCESS,
} from '../types';

const initialState = {
  admin: {},
  isLoading: false,
  error: {},
  isUpdatingAdmin: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMIN_INFO: {
      return { ...state, isLoading: true };
    }

    case GET_ADMIN_INFO_SUCCESS: {
      const { payload } = action;

      return { ...state, admin: payload, isLoading: false };
    }

    case GET_ADMIN_INFO_FAIL: {
      const { payload } = action;

      return { ...state, error: payload, isLoading: false };
    }

    case ADMIN_LOGIN: {
      return { ...state, isLoading: true };
    }

    case ADMIN_LOGIN_SUCCESS: {
      const { payload } = action;

      return { ...state, admin: payload, isLoading: false };
    }

    case ADMIN_LOGIN_FAIL: {
      const { payload } = action;

      return { ...state, error: payload, isLoading: false };
    }

    case CLEAR_AUTH_STATE: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
