import { isEmpty as isStringEmpty } from './string';

export const convert12hrTo24hr = (hour) => {
  if (isStringEmpty(hour)) {
    return hour;
  }

  const regex = /[0-9]+(am|pm)/;

  const trimHour = hour.trim();
  if (!regex.test(trimHour)) throw new Error('Not valid format');

  const hour12 = parseInt(trimHour.replace(/(am|pm)/, ''));
  const amPm = trimHour.substr(trimHour.length - 2, 2);

  if (hour12 > 12 || hour12 <= 0) throw new Error('Hour must be between 1 and 12 including');

  if (amPm === 'am') return hour12 % 12;

  return (hour12 % 12) + 12;
};

export const convert24HourTo12hr = (startsAt, endsAt) => {
  let startTime = '';
  let endTime = '';

  if (startsAt === 0) {
    startTime = 12 + 'am';
  } else if (startsAt < 12) {
    startTime = startsAt + 'am';
  } else if (startsAt === 12) {
    startTime = 12 + 'pm';
  } else {
    startTime = startsAt - 12;
    startTime = startTime + 'pm';
  }

  if (endsAt === 0) {
    endTime = 12 + 'am';
  } else if (endsAt < 12) {
    endTime = endsAt + 'am';
  } else if (endsAt === 12) {
    endTime = 12 + 'pm';
  } else {
    endTime = endsAt - 12;
    endTime = endTime + 'pm';
  }

  return `${startTime} - ${endTime}`;
};
