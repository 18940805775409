import http from '../utils/http';
import { interpolate } from 'utils/string';

import * as endpoints from '../constants/endpoints';

export const addCategory = async (category) => {
  const { data } = await http.post(endpoints.CATEGORIES, {
    body: category,
  });

  return data;
};

export const updateCategory = async (id, category) => {
  const { data } = await http.patch(interpolate(endpoints.CATEGORY, { id }), {
    body: category,
  });

  return data;
};

export const getCategoryById = async (id) => {
  const { data } = await http.get(
    interpolate(endpoints.CATEGORY, {
      id,
    })
  );

  return data;
};
