import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Table from 'components/Table';
import Search from '../../components/Search';
import Loader from '../../components/Loader/Loader';
import LoginInfo from '../../components/LoginInfo/LoginInfo';
import PageTitle from '../../components/PageTitle/PageTitle';

import { fetchUserOptions } from '../../service/options';

import { isEmpty as isStringEmpty } from '../../utils/string';

const MyDeals = (props) => {
  const { getUserList, users, isLoading } = props;
  const tableData = users;

  const initialFilters = {
    id: null,
  };

  const [appliedFilters, setAppliedFilters] = useState(initialFilters);

  const updateAppliedFilter = (key, value) => {
    setAppliedFilters((prevFilter) => ({
      ...prevFilter,
      [key]: value,
    }));
  };

  useEffect(() => {
    getUserList(appliedFilters);
  }, [appliedFilters]);

  const fetchByUserName = useCallback(
    (searchText) =>
      fetchUserOptions({
        fullName: searchText,
      }),
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'fullName',
      },
      {
        Header: 'Age',
        accessor: 'age',
      },
      {
        Header: 'Last Logged In',
        accessor: 'lastLoggedIn',
        Cell: (cellInfo) => {
          const value = cellInfo.cell.value;

          if (isStringEmpty(value)) return '-';

          return dayjs(value).format('MMMM D, YYYY h:mm A');
        },
      },
      {
        Header: 'Date Created',
        accessor: 'createdAt',
        Cell: (cellInfo) => {
          const value = cellInfo.cell.value;

          if (isStringEmpty(value)) return '-';
          return dayjs(value).format('DD/MM/YYYY');
        },
      },
    ],
    []
  );

  const renderTable = () => {
    if (isLoading) {
      return <Loader />;
    }

    return <Table data={tableData} columns={columns} />;
  };

  return (
    <div className="w-100">
      <div className="d-flex justify-content-between mb-4">
        <PageTitle title="Users" />
        <LoginInfo />
      </div>

      <div className="row">
        <div className="col-2">
          <Search
            label="Name:"
            placeholder="Search By Name"
            fetchOptionsFunction={fetchByUserName}
            onChange={(selectedOption) => updateAppliedFilter('id', selectedOption?.value)}
            isClearable
          />
        </div>
      </div>

      <div className="table-container">{renderTable()}</div>
    </div>
  );
};

MyDeals.propTypes = {
  getUserList: PropTypes.func,
  users: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default MyDeals;
