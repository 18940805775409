import React from 'react';
import { NavLink } from 'react-router-dom';
// import { FaWallet } from 'react-icons/fa';
import { FaTag, FaMapMarkerAlt, FaUserAlt, FaShoppingBag, FaCoffee } from 'react-icons/fa';

import ROUTES from '../../constants/routes';

const SideNav = () => {
  const navItems = [
    // {
    //   route: ROUTES.ROOT,
    //   Icon: FaHome,
    //   title: 'Home',
    //   exact: true,
    // },
    // {/* Removed for Release */}
    // {
    //   route: ROUTES.SPENDING,
    //   Icon: FaWallet,
    //   title: 'Billing',
    // },
    {
      route: ROUTES.DEALS,
      Icon: FaTag,
      title: 'Deals',
    },
    {
      route: ROUTES.LOCATION,
      Icon: FaMapMarkerAlt,
      title: 'Locations',
    },
    {
      route: ROUTES.USERS,
      Icon: FaUserAlt,
      title: 'Users',
    },
    {
      route: ROUTES.CUSTOMERS,
      Icon: FaShoppingBag,
      title: 'Customers',
    },
    {
      route: ROUTES.CATEGORIES,
      Icon: FaCoffee,
      title: 'Categories',
    },
  ];

  return (
    <div className="side-nav">
      {navItems.map(({ route, Icon, title, exact = false }, key) => (
        <NavLink
          exact={exact}
          to={route}
          key={key}
          className="side-nav__item mb-4"
          activeClassName="side-nav__item--active"
        >
          <div className="d-flex flex-column align-items-center">
            <Icon className="side-nav__item__icon" />
            <div className="side-nav__item__title">{title}</div>
          </div>
        </NavLink>
      ))}
    </div>
  );
};

export default SideNav;
