import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FaInfoCircle } from 'react-icons/fa';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Table from 'components/Table';
import Search from '../../components/Search';
import Loader from '../../components/Loader/Loader';
import LoginInfo from '../../components/LoginInfo/LoginInfo';
import PageTitle from '../../components/PageTitle/PageTitle';
import { fetchAreaOptions, fetchCompanyOptions, fetchLocationOptions } from '../../service/options';

import ROUTES from '../../constants/routes';

import { interpolate } from '../../utils/string';

const Locations = (props) => {
  const { getLocationList, locations, isLoading } = props;

  const initialFilters = {
    id: null,
    areaId: null,
    companyId: null,
  };

  const [appliedFilters, setAppliedFilters] = useState(initialFilters);

  const updateAppliedFilter = (key, value) => {
    setAppliedFilters((prevFilter) => ({
      ...prevFilter,
      [key]: value,
    }));
  };

  useEffect(() => {
    getLocationList(appliedFilters);
  }, [appliedFilters]);

  const fetchLocationByName = useCallback(
    (searchText) =>
      fetchLocationOptions({
        name: searchText,
      }),
    []
  );

  const fetchAreaByName = useCallback(
    (searchText) =>
      fetchAreaOptions({
        name: searchText,
      }),
    []
  );

  const fetchCompanyByName = useCallback(
    (searchText) =>
      fetchCompanyOptions({
        name: searchText,
      }),
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Location',
        accessor: 'name',
      },
      {
        Header: 'Business Type',
        accessor: 'businessType',
      },
      {
        Header: 'Address',
        accessor: 'addressLine1',
      },
      {
        Header: 'Status',
        accessor: 'status',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => {
          const statusClassMaps = {
            Active: 'approved',
            Inactive: 'denied',
            pending: 'pending',
            cancelled: 'cancelled',
          };

          return <div className={`text-transform-capitalize ${statusClassMaps?.[value] || ''}`}>{value}</div>;
        },
      },
      {
        id: 'action',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original = {} } }) => {
          const editUrl = interpolate(ROUTES.EDIT_LOCATION, { locationId: original?._id || '' });

          return (
            <Link className="text-decoration-none link-button color-windows-blue" to={editUrl}>
              Edit
            </Link>
          );
        },
      },
    ],
    []
  );

  const tableData = useMemo(() => {
    const extractTableData = (locations) =>
      locations.map((location) => ({
        ...location,
        status: location?.isActive === true ? 'Active' : 'Inactive',
      }));

    const locationData = locations;
    const tableData = extractTableData(locationData);

    return tableData;
  }, [locations]);

  const renderLocationTable = () => {
    if (isLoading) {
      return <Loader />;
    }

    return <Table data={tableData} columns={columns} />;
  };

  return (
    <div className="w-100">
      <div className="d-flex justify-content-between mb-4">
        <PageTitle title="Locations" />
        <LoginInfo />
      </div>
      <div className="row">
        <div className="col-2">
          <Search
            label="Location:"
            placeholder="Search By Location"
            fetchOptionsFunction={fetchLocationByName}
            onChange={(selectedOption) => updateAppliedFilter('id', selectedOption?.value)}
            isClearable
          />
        </div>
        <div className="col-2">
          <Search
            label="Area:"
            placeholder="Search By Area"
            fetchOptionsFunction={fetchAreaByName}
            onChange={(selectedOption) => updateAppliedFilter('areaId', selectedOption?.value)}
            isClearable
          />
        </div>
        <div className="col-2">
          <Search
            label="Company:"
            placeholder="Search By Company"
            fetchOptionsFunction={fetchCompanyByName}
            onChange={(selectedOption) => updateAppliedFilter('companyId', selectedOption?.value)}
            isClearable
          />
        </div>
      </div>
      <div className="table-container">{renderLocationTable()}</div>
      <div className="d-flex justify-content-between location-info">
        <div className="d-flex align-items-center">
          <FaInfoCircle color="#007aff" /> &nbsp; Click to edit the location
        </div>
        <Link className="text-decoration-none" to={ROUTES.ADD_LOCATION}>
          <button className="d-flex align-items-center link-button">
            Add a new location &nbsp;
            <div className="d-flex align-items-center justify-content-center add-location">+</div>
          </button>
        </Link>
      </div>
    </div>
  );
};

Locations.propTypes = {
  getLocationList: PropTypes.func,
  locations: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default Locations;
