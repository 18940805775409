import { connect } from 'react-redux';

import { clearAuthState } from '../../redux/actions/auth';

import Component from './Header';

const mapStateToProps = (state, props) => {
  return {
    loggedInAdmin: state.auth.admin,
  };
};

const mapDispatchToProps = {
  clearAuthState,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
