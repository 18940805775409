import { GET_USER_LIST, GET_USER_LIST_FAIL, GET_USER_LIST_SUCCESS } from '../types';

import * as userService from '../../service/user';

export const getUserList = (filters = {}) => {
  return function (dispatch) {
    dispatch({ type: GET_USER_LIST });

    const onGetUserListSuccess = (response) =>
      dispatch({
        type: GET_USER_LIST_SUCCESS,
        payload: response,
      });

    const onGetUserListError = (error) => dispatch({ type: GET_USER_LIST_FAIL, payload: error });

    return userService.getUsers(filters).then(onGetUserListSuccess).catch(onGetUserListError);
  };
};
