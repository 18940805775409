import * as toast from './toast';

const GENERIC_ERROR = 'Oops! Something went wrong';

/**
 * Generic error handler to handle error.
 *
 * @param {object} error
 * @param {{title, message}} options
 */
export const handleError = (error, options = {}) => {
  const message = error?.message || GENERIC_ERROR;

  toast.error({
    title: options.title || 'Error',
    message: options.message || message,
  });
};
