import React from 'react';
import PropTypes from 'prop-types';

import { get } from 'utils/object';

import glyphmapIndex from 'assets/generated/glyphmapIndex.json';

const CategoryPreview = (props) => {
  const { title, subTitle, icon, backgroundColor, color, iconLibrary } = props;
  const extractCodePoint = (fontFamily, icon) => get(glyphmapIndex, `${fontFamily}.${icon}`, '');

  return (
    <div
      className="category-preview"
      style={{
        backgroundColor: backgroundColor,
        color: color,
      }}
    >
      {iconLibrary && icon && (
        <div className="category-preview__icon" style={{ fontFamily: iconLibrary }}>
          {String.fromCodePoint(extractCodePoint(iconLibrary, icon))}
        </div>
      )}
      <div className="category-preview-title-container">
        {title && <div className="category-preview-title">{title}</div>}
        <div className="category-preview-sub-title">{subTitle}</div>
      </div>
    </div>
  );
};

CategoryPreview.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  icon: PropTypes.string,
  iconLibrary: PropTypes.string,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
};

export default CategoryPreview;
