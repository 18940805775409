import React from 'react';
import { Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import AppRouter from './Router';
import history from './utils/history';

import { Provider } from 'react-redux';

import store from './redux/store';

const App = () => {
  return (
    <Provider store={store}>
      <HelmetProvider>
        <Router history={history}>
          <AppRouter />
        </Router>
      </HelmetProvider>
    </Provider>
  );
};

export default App;
