import { connect } from 'react-redux';

import { getUserList } from '../../redux/actions/users';

import Component from './Users';

const mapStateToProps = (state, props) => {
  return {
    isLoading: state.user.isLoading,
    users: state.user.users,
  };
};

const mapDispatchToProps = { getUserList };

export default connect(mapStateToProps, mapDispatchToProps)(Component);
