import { GET_CATEGORY_LIST, GET_CATEGORY_LIST_FAIL, GET_CATEGORY_LIST_SUCCESS } from '../types';

const initState = {
  categories: [],
  error: null,
  isLoading: false,
};

export default (state = initState, action) => {
  switch (action.type) {
    case GET_CATEGORY_LIST: {
      return { ...state, isLoading: true };
    }

    case GET_CATEGORY_LIST_SUCCESS: {
      const { payload } = action;

      return { ...state, isLoading: false, categories: payload, error: null };
    }

    case GET_CATEGORY_LIST_FAIL: {
      const { payload } = action;

      return { ...state, isLoading: false, categories: [], error: payload };
    }

    default:
      return state;
  }
};
