import { combineReducers } from 'redux';

import auth from './auth';
import area from './area';
import deal from './deal';
import user from './user';
import company from './company';
import customer from './customer';
import category from './category';
import location from './location';

export const rootReducer = combineReducers({
  auth,
  area,
  deal,
  user,
  company,
  customer,
  category,
  location,
});
