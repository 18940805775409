import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const InputLabel = ({ label, className }) => <div className={classnames('text-input__label', className)}>{label}</div>;

InputLabel.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default InputLabel;
