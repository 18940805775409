import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ImageUploading from 'react-images-uploading';

import ImageCropModal from 'components/ImageCropModal/ImageCropModal';

import userDefaultAvatar from 'assets/images/user-default-avatar.png';

function UserImageUpload(props) {
  const { image, onImageChange, errorMessage } = props;

  const [uploadedImage, setUploadedImage] = useState();
  const [showImageCropModal, setShowImageCropModal] = useState(false);

  const onChange = (file) => {
    const image = file?.[0]?.data_url;

    setUploadedImage(image);
    setShowImageCropModal(true);
  };

  return (
    <section className="row mt-4">
      <ImageUploading value={image} dataURLKey="data_url" onChange={onChange}>
        {({ imageList, onImageUpload, onImageUpdate, isDragging, dragProps }) => {
          const avatarUrl = imageList?.[0]?.data_url;
          const isImageChosen = imageList?.length > 0;
          const handleClick = () => {
            if (isImageChosen) {
              onImageUpdate(0);
            } else {
              onImageUpload();
            }
          };

          return (
            <div className="col-md-12 col-lg-6">
              <div className="d-flex align-items-end">
                <img src={avatarUrl || userDefaultAvatar} className="border-radius-50 edit-profile__user-avatar" />

                <div className="ml-4">
                  <button
                    className={`fs-16px fw-400 color-white edit-profile__main-button cursor-pointer ${
                      isDragging ? 'layer' : ''
                    }`}
                    onClick={handleClick}
                    {...dragProps}
                  >
                    Choose a file
                  </button>

                  <p className="fs-10px fw-400 color-greyish-brown" style={{ maxWidth: 200 }}>
                    Acceptable formats: jpg. png. only. Max file size is 500kb and min. size is 70kb.
                  </p>
                </div>
              </div>

              {errorMessage && (
                <div className="upload__info fs-14px fw-500 mt-3 ml-2 p-2 color-red-fire">{errorMessage}</div>
              )}
            </div>
          );
        }}
      </ImageUploading>

      {showImageCropModal && (
        <ImageCropModal
          open={showImageCropModal}
          aspectRatio={5 / 5}
          image={uploadedImage}
          onImageCropComplete={onImageChange}
          onModalClose={() => setShowImageCropModal(false)}
        />
      )}
    </section>
  );
}

UserImageUpload.propTypes = {
  image: PropTypes.array,
  errorMessage: PropTypes.string,
  onImageChange: PropTypes.func.isRequired,
};

export default UserImageUpload;
