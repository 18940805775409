import React from 'react';
import PropTypes from 'prop-types';

import { isEmpty as isObjectEmpty } from '../../utils/object';

import ROUTES from '../../constants/routes';

import Button from '../Button/Button';

import userDefaultAvatar from '../../assets/images/user-default-avatar.png';
import hourlyCombinationMarkLogo from '../../assets/images/hourly-logo-combination-mark.png';

const Header = (props) => {
  const handleLogoutClick = () => {
    props.clearAuthState();
    window.location.href = ROUTES.AUTH.LOGIN;
  };

  const isUserLoggedIn = !isObjectEmpty(props.loggedInAdmin);

  return (
    <div className="header">
      <img className="hourly-header-icon" src={hourlyCombinationMarkLogo} alt="hourly combination mark" />

      {isUserLoggedIn && (
        <div className="d-flex align-items-center">
          <div className="user-container mr-3">
            <p className="m-0">{props.loggedInAdmin?.email}</p>
          </div>
          <img src={userDefaultAvatar} className="user-header-avatar mr-5 border-radius-50" />

          <Button title="LOG OUT" className="button-logout" onClick={handleLogoutClick} />
        </div>
      )}
    </div>
  );
};

Header.propTypes = {
  loggedInAdmin: PropTypes.object,
  clearAuthState: PropTypes.func.isRequired,
};

export default Header;
