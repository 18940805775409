import React from 'react';

const LoginInfo = () => {
  return (
    <div className="login-info">
      <div className="login-info__last-login-date-time">23:14, Thursday, Oct 15, 2019</div>
      <div className="login-info__last-login-relative-time">20 days since your last visit.</div>
    </div>
  );
};

export default LoginInfo;
